import React from 'react' 
import { useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';
import { Border } from '@syncfusion/ej2-react-charts';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function Chart() { 

    const options = {
        animationEnabled: true,
        theme: "light2",
        title: {
            text: "",
        
        },
        subtitles: [{
            text: ""
        }],
        axisY: {
            prefix: "₹"
        },
        toolTip: {
            shared: true
        },
        data: [
        {
            type: "area",
            name: "GOLD",
            showInLegend: true,
            xValueFormatString: "YYYY",
            yValueFormatString: "₹#,##0.##",
            dataPoints: [
                { x: new Date("2017- 01- 01"), y: 60.927},
                { x: new Date("2018- 02- 01"), y: 82.609},
                { x: new Date("2019- 03- 01"), y: 81.428},
                { x: new Date("2020- 04- 01"), y: 83.259},
                { x: new Date("2021- 05- 01"), y: 83.153},
                { x: new Date("2022- 06- 01"), y: 61.180},
            ]
        },
        {
            type: "area",
            name: "FD",
            showInLegend: true,
            xValueFormatString: "YYYY",
            yValueFormatString: "₹#,##0.##",
            dataPoints: [
                { x: new Date("2017- 01- 01"), y: 65.515},
                { x: new Date("2018- 02- 01"), y: 66.725},
                { x: new Date("2019- 03- 01"), y: 64.86},
                { x: new Date("2020- 04- 01"), y: 64.29},
                { x: new Date("2021- 05- 01"), y: 64.51},
                { x: new Date("2022- 06- 01"), y: 75.62},
            ]
        }
        ]
    } 

    const options2 = {
        animationEnabled: true,
        title: {
            text: ""
        },
        axisY: {
            title: "",
            suffix: "₹"
        },
        data: [
            {
            type: "area",
            xValueFormatString: "YYYY",
            yValueFormatString: "#,##0.## bn",
            showInLegend: true,
            legendText: "GOLD",
            color : '#E9B8B8',
        
            dataPoints: [
                { x: new Date(2008, 0), y: 70.735 },
                { x: new Date(2009, 0), y: 74.102 },
                { x: new Date(2010, 0), y: 72.569 },
                { x: new Date(2011, 0), y: 72.743 },
                { x: new Date(2012, 0), y: 72.381 },
                { x: new Date(2013, 0), y: 71.406 },
                { x: new Date(2014, 0), y: 73.163 },
                { x: new Date(2015, 0), y: 74.270 },
                { x: new Date(2016, 0), y: 72.525 },
                { x: new Date(2017, 0), y: 73.121 }
            ]
        }]
    }

  return (
    <section >
      <div className='container'>
         <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
           <div>
            <img src='/assets/images/shpas.png' /> 
            <h2 className='fw-bold'>How is it better than FD</h2> 
            <h5>A 1000 investment 5 years ago would be:</h5>
          </div>
        </div>  

        <div className='p-4 border rounded-4'>
				<CanvasJSChart options = {options2}
				  /* onRef={ref => this.chart = ref} */
				/>
		</div>
            
      </div> 
  </section> 
  )
}
