import React, { useEffect, useState } from 'react'
import { getfaq_fun } from '../api/Pageapi'
import { Shimmer } from 'react-shimmer'



export default function FAQ() {



    const [faqdata, setfaqdata] = useState([])
    const [Isloading, setIsloading] = useState(true)

    const FAq_List = () => {

        getfaq_fun()
            .then((response) => {
                setfaqdata(response?.data)
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsloading(true)
            })
    }

    useEffect(() => {
        FAq_List()
    }, [])



    return (
        <div className='gaq-B'>
            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-4'>
                    <div>
                        <img src='/assets/images/shpas.png' />
                        <h2 className='fw-bold'>Frequently Asked Questions</h2>
                    </div>
                </div>


                <div className="accordion row" id="accordionPanelsStayOpenExample">

                    {

                        Isloading ? Array.from({ length: 6 }).map((_, i) => {
                            return (
                                <div className='col-sm-6 mb-3' key={i}>
                                   <div><Shimmer  height={80} className='rounded w-100' /></div>
                                </div>
                            )
                        }) :

                            faqdata ? faqdata.map((r, i) => {
                                return (
                                    <div className='col-sm-6 mb-3' key={i}>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header">
                                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#${r.id}`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                    {r.title}
                                                </button>
                                            </h2>
                                            <div id={r.id} className="accordion-collapse collapse">
                                                <div className="accordion-body">
                                                    <p className='four_line'>{r.description}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : ""
                    }

                    {/* <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                      4. Can we buy and store Digi Gold ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                     2. How to convert Digi Gold to Jewellery ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse show">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree3" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree3">
                     5. Can we cancel the purchase at any time ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree3" className="accordion-collapse collapse show">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div> 

            <div className='col-sm-6 mb-3'>
                <div className="accordion-item">
                    <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree4" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree4">
                    3. How often does the live price change ?
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree4" className="accordion-collapse collapse">
                    <div className="accordion-body">
                    <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                    </div>
                    </div>
                </div>
            </div>

            <div className='col-sm-6 mb-3'>
                 <div className="accordion-item">
                     <h2 className="accordion-header">
                     <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree6" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree6">
                      6. What is live gold price ?
                     </button>
                     </h2>
                     <div id="panelsStayOpen-collapseThree6" className="accordion-collapse collapse">
                     <div className="accordion-body">
                      <p>Open greenheap Digi Gold Website. Tap on Transfer/Gift. The minimum amount to Transfer/Gift process is INR 100 or above. Enter the mobile number of the recipient and verify the details(Name). Enter the quantity or amount to transfer. Check the terms and conditions. After the process is completed, the equivalent gold will be transferred to the recipient account within a few hours.</p>
                     </div>
                     </div>
                 </div>
            </div> */}
                </div>

            </div>
        </div>
    )
}
