import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import Logout from "./Login/Logout";
import { Logindetail } from "../context/Logindetail";
import { Filter } from "react-bootstrap-icons";


const Dashboard = ({setting_data}) => {


    const [isloading ,setisloading]=useState(false)




    return (
        <>
            <section>
                <div className="customer-pages">
                    <Link to="/user/dashboard">  <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\home.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/dashboard" ? "active" : "inactive"}>My Dashboard</h4>
                        </div>
                    </div> </Link>

                    <Link to="/user/myprofile"> <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\Profile.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/myprofile" ? "active" : "inactive"}>My Profile</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/kyc">  <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\KYC.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/kyc" ? "active" : "inactive"}>KYC</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/beneficiaries"> <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\SIP.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/beneficiaries" ? "active" : "inactive"}>Beneficiaries</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/chit-jewels">  <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                             <h4 className={window.location.pathname === "/user/chit-jewels" ? "active" : "inactive"}>{ setting_data ? setting_data[0]?.saving_plan : ''}</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/digital-gold">   <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/digital-gold" ? "active" : "inactive"}>{ setting_data  ? setting_data[0]?.digital_plan : ''}</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/gold-plant"> <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                             <h4 className={window.location.pathname === "/user/gold-plant" ? "active" : "inactive"}>{ setting_data ? setting_data[0]?.gold_plant : ''}</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/Notifycation"> <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\notification.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                          <h4 className={window.location.pathname === "/user/Notifycation" ? "active" : "inactive"}>Notification</h4>
                        </div>
                    </div></Link>

                    <Link to="/user/mybank-account"><div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\bank.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <h4 className={window.location.pathname === "/user/mybank-account" ? "active" : "inactive"}>My Bank Accounts</h4>
                        </div>
                    </div></Link>
                    {/* <div className="dashboard-list d-flex align-items-center gap-3">
                        <div className="page-icon">
                            <img src="\assets\iocns\orders.png" className="img-fluid" />
                        </div>
                        <div className="page-name">
                            <Link to="/user/myorders"> <h4 className={window.location.pathname === "/user/myorders" ? "active" : "inactive"}>My Orders</h4></Link>
                        </div>
                    </div> */}
                    <div className="dashboard-list border-0 d-flex align-items-center gap-3" data-bs-toggle="modal" data-bs-target="#logout_modal" >
                        <div className="page-icon">
                            <img src="\assets\iocns\Logout.png" className="img-fluid" />
                        </div>
                        <div className="page-name" >
                            <h4 className="active">Logout</h4>
                        </div>
                    </div>

                </div>

                <div className="mb-pages">
                    <button className="btn " type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasLeft" aria-controls="offcanvasLeft"><Filter /></button>

                    <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasLeft" aria-labelledby="offcanvasLeftLabel">
                        <div className="offcanvas-header">
                            <img src="/assets/images/green.png" className="mainIcon" alt="greenheap" />
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">

                            <div className="filter-pages">
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\home.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/dashboard"> <h4 className={window.location.pathname === "/user/dashboard" ? "active" : "inactive"}>My Dashboard</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\Profile.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/myprofile">  <h4 className={window.location.pathname === "/user/myprofile" ? "active" : "inactive"}>My Profile</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\KYC.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/kyc">  <h4 className={window.location.pathname === "/user/kyc" ? "active" : "inactive"}>KYC</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\SIP.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/beneficiaries">  <h4 className={window.location.pathname === "/user/beneficiaries" ? "active" : "inactive"}>Beneficiaries</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/chit-jewels">  <h4 className={window.location.pathname === "/user/chit-jewels" ? "active" : "inactive"}>Chit Jewels Saving Plan</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/digital-gold">  <h4 className={window.location.pathname === "/user/digital-gold" ? "active" : "inactive"}>Digital Gold Plan</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\right arrow round.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/gold-plant">  <h4 className={window.location.pathname === "/user/gold-plant" ? "active" : "inactive"}>Gold Plant Schemes Plan</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\notification.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/Notifycation"> <h4 className={window.location.pathname === "/user/Notifycation" ? "active" : "inactive"}>Notification</h4></Link>
                                    </div>
                                </div>
                                <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\bank.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/mybank-account"> <h4 className={window.location.pathname === "/user/mybank-account" ? "active" : "inactive"}>My Bank Accounts</h4></Link>
                                    </div>
                                </div>
                                {/* <div className="dashboard-list d-flex align-items-center gap-3">
                                    <div className="page-icon">
                                        <img src="\assets\iocns\orders.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name">
                                        <Link to="/user/myorders"> <h4 className={window.location.pathname === "/user/myorders" ? "active" : "inactive"}>My Orders</h4></Link>
                                    </div>
                                </div>  */}
                                <div className="dashboard-list border-0 d-flex align-items-center gap-3" data-bs-toggle="modal" data-bs-target="#logout_modal" >
                                    <div className="page-icon">
                                        <img src="\assets\iocns\Logout.png" className="img-fluid" />
                                    </div>
                                    <div className="page-name" >
                                        <h4 className="active">Logout</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </section>




            <Logout />

        </>
    )
}

export default Dashboard;