import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { Shimmer } from "react-shimmer";
import {notification_fun } from "../api/Dashboardapi.js";
import moment from "moment";


const Notifycation = ({settings}) => {


    const [notify_data, setnotify_data] = useState([])
    const [isloading, setisloading] = useState(true)

    const getnotification = async () => {

        setisloading(true)

        notification_fun()
        .then((res) => {
            console.log(res?.data)
            setnotify_data(res?.data); 
            setisloading(false)
        })
        .catch((err) => {
            console.log(err); 
            setisloading(false)
        });
        
    }

    

    useEffect(() => {
        moment.updateLocale('en', {
            relativeTime : {
                future: "in %s",
                past:   "%s ago",
                s  : 'a few seconds',
                ss : '%d seconds',
                m:  "a minute",
                mm: "%d minutes",
                h:  "1 hour ago", //this is the setting that you need to change
                hh: "%d hours",
                d:  "a day",
                dd: "%d days",
                w:  "a week",
                ww: "%d weeks",
                M:  "1 month ago", //change this for month
                MM: "%d months",
                y:  "a year",
                yy: "%d years"
            }
        });
 
       getnotification()
    }, [])

    return (
        <>
             <HeadingBanner title={"Notifycation"} />
            <section>
                <div className="container">
                    <div className="notifycation-layout">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5">
                            <Dashboard setting_data={settings} />
                            </div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="notifycation-content">
                                    <div className="notificatin-head pb-3">
                                        <h4>Notification</h4>
                                    </div>
                                    <div className="notify-list border py-3 px-5">
                                        {/* <div className="row">
                                            <div className="col-xl-2 col-lg-3 col-md-2 col-sm-4">
                                                <div className="notify-img text-center my-sm-5 my-md-0">
                                                    <img src="\assets\images\tanishq.png" className="img-fluid" />
                                                </div>
                                            </div>
                                            <div className="col-xl-10 col-lg-9 col-md-10 col-sm-8">
                                                <div className="notify-section ">
                                                    <div className="notify-sec1 d-flex flex-wrap align-items-center justify-content-between">
                                                        <h4>Tanishq Digital Gold Offers...</h4>
                                                        <h6>20 mins ago</h6>
                                                    </div>
                                                    <div className="notify-sec2">
                                                        <p>Over to it since the jwellery at Tanishq is already way to expensive therfore hallmarking  the jewellery could burn some more pockets because there could charge this also to customer</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         */}


                                        <div >
                                            {  isloading ? 
                                                <div className="row notify-loader">
                                                <div className="col-xl-2 col-lg-3 col-md-2 col-sm-4">
                                                    <div className="notify-img text-center my-sm-5 my-md-0">
                                                       <Shimmer className="rounded-circle" width={60} height={60}/>
                                                    </div>
                                                </div>
                                                <div className="col-xl-10 col-lg-9 col-md-10 col-sm-8">
                                                    <div className="notify-section ">
                                                        <div className="notify-sec1 d-flex flex-wrap align-items-center justify-content-between">
                                                            <h4><Shimmer width={80} height={20}/></h4>
                                                            <h6><Shimmer width={30} height={15}/></h6>
                                                        </div>
                                                        <div className="notify-sec2">
                                                          <Shimmer width={400} height={40} />
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                              :
                                                notify_data ? notify_data.map((data,i) => {
                                                    return (
                                                        <div className="row" key={i}>
                                                            <div className="col-xl-2 col-lg-3 col-md-2 col-sm-4">
                                                                <div className="notify-img text-center my-sm-5 my-md-0">
                                                                    <img src= {data?.image} className="img-fluid" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-10 col-lg-9 col-md-10 col-sm-8">
                                                                <div className="notify-section ">
                                                                    <div className="notify-sec1 d-flex flex-wrap align-items-center justify-content-between">
                                                                        <h4>{data?.title}</h4>
                                                                        <h6>{moment(data?.updated_at).fromNow()}</h6>
                                                                    </div>
                                                                    <div className="notify-sec2">
                                                                        <p>{data?.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }) : ""
                                              }
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )

}
export default Notifycation;