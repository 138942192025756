import React, { useEffect, useState } from 'react'
import HeadingBanner from '../Components/HeadingBanner.jsx'
import FAQ from '../Components/FAQ.jsx'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Grid } from "swiper/modules";
import { Shimmer } from 'react-shimmer';
import { trestiAPi } from '../api/Controllapi.js';
import { whychoose, Gettrust_fun, Getabout_fun } from '../api/Pageapi.js';



export default function About() {


  const [testimoanl_data, settestimoanl_data] = useState([])
  const [Isloading, setIsloading] = useState(true)
  const [ChooseData, setChooseData] = useState()
  const [Trustdata, setTrustData] = useState()
  const [AboutData, setAboutData] = useState({})

  const testimonal_fun = async () => {
    trestiAPi()
      .then((res) => {
        setIsloading(true)
        settestimoanl_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }


  const getWhyChoose = () => {
    whychoose()
      .then((res) => {
        setChooseData(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const gettrust = () => {
    Gettrust_fun()
      .then((res) => {
        setTrustData(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getaboutdetail = () => {
    Getabout_fun()
      .then((res) => {
        setAboutData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    testimonal_fun()
    getWhyChoose()
    gettrust()
    getaboutdetail()
  }, [])



  return (
    <>
      <HeadingBanner title={"About Us"} />

      {/* <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6  col-md-7 mb-3'>
              <div className='mb-3'>
                <h2 className='fw-bold lh-lg mb-3'>
                  Get access to the safest way of procuring... 24K Gold / Silver
                </h2>

                <h4 className='lh-base aboutsub text-secondary'>
                  We at DigiGold want to make your gold journey simple, transparent and trustworthy so that you can get the optimum output of your savings.
                </h4>
              </div>

              <div className='d-flex gap-xl-3 gap-lg-2 gap-3 gap-md-2 justify-content-sm-center justify-content-md-start gap-sm-3 flex-wrap align-items-center  '>
                <div className='delvdd border rounded-3 flex-column'>
                  <img src='/assets/iocns/Delivery.png' className='mb-3' />
                  <div>
                    <h4>Delivery</h4>
                  </div>
                </div>

                <div className='delvdd border rounded-3 flex-column'>
                  <img src='/assets/iocns/SIP.png' className='mb-3' />
                  <div>
                    <h4>SIP</h4>
                  </div>
                </div>

                <div className='delvdd border rounded-3 flex-column'>
                  <img src='/assets/iocns/Gift.png' className='mb-3' />
                  <div>
                    <h4>Gift</h4>
                  </div>
                </div>

              </div>

            </div>
            <div className='col-lg-5 col-md-5 offset-lg-1 offset-md-0 mb-3 '>
              <div className='svvv text-center'>
                <img src='/assets/images/about us.png' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className='container'>
          {
            Isloading ? (<div className='row'>
              <div className='col-lg-6  col-md-7 mb-3'>
                <div className='mb-3'>
                  <h2 className='fw-bold lh-lg mb-3'>
                    <Shimmer className='w-75 rounded' height={60} />
                  </h2>

                  <h4 className='lh-base aboutsub text-secondary'>
                    <Shimmer className='w-100 rounded' height={230} />
                  </h4>
                </div>

                <div className='d-flex gap-xl-3 gap-lg-2 gap-3 gap-md-2 justify-content-sm-center justify-content-md-start gap-sm-3 flex-wrap align-items-center  '>
                  <div >
                    <Shimmer className='rounded' width={150} height={140} />
                  </div>

                  <div>
                    <Shimmer className='rounded' width={150} height={140} />
                  </div>

                  <div>
                    <Shimmer className='rounded' width={150} height={140} />
                  </div>
                </div>

              </div>
              <div className='col-lg-5 col-md-5 offset-lg-1 offset-md-0 mb-3 '>
                <div>
                  <Shimmer className='rounded w-100' height={500} />
                </div>
              </div>
            </div>)
            
            : AboutData ?
              ( <div className='row'>
                <div className='col-lg-6  col-md-7 mb-3'>
                  <div className='mb-3'>
                    <h2 className='fw-bold lh-lg mb-3'>
                      {AboutData?.title}
                    </h2>

                    <h4 className='lh-base aboutsub text-secondary'>
                      {AboutData?.description}
                    </h4>
                  </div>

                  <div className='d-flex gap-xl-3 gap-lg-2 gap-3 gap-md-2 justify-content-sm-center justify-content-md-start gap-sm-3 flex-wrap align-items-center  '>
                    <div className='delvdd border rounded-3 flex-column'>
                      <img src={AboutData?.title_image_1} alt='' className='mb-3' />
                      <div>
                        <h4>{AboutData?.title_name_1}</h4>
                      </div>
                    </div>

                    <div className='delvdd border rounded-3 flex-column'>
                      <img src={AboutData?.title_image_2} alt='' className='mb-3' />
                      <div>
                        <h4>{AboutData?.title_name_2}</h4>
                      </div>
                    </div>

                    <div className='delvdd border rounded-3 flex-column'>
                      <img src={AboutData?.title_image_3} alt='' className='mb-3' />
                      <div>
                        <h4>{AboutData?.title_name_3}</h4>
                      </div>
                    </div>

                  </div>

                </div>
                <div className='col-lg-5 col-md-5 offset-lg-1 offset-md-0 mb-3 '>
                  <div className='svvv text-center'>
                    <img src={AboutData?.main_image} alt='' className='img-fluid' />
                  </div>
                </div>
              </div>
            ) : ''
          }

        </div>
      </section>

      <section className='schema2'>
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
            <div>
              <img src='/assets/images/shpas.png' />
              <h2 className='fw-bold'>Why Choose Us?</h2>
            </div>
          </div>

          <div className='row'>
            {
              Isloading ? Array.from({ length: 6 }).map((_, i) => {

                return (
                  <div className='col-lg-4 col-sm-6 mb-3 mb-3' key={i}>
                    <div className='pshycal h-100'>
                      <Shimmer width={70} height={60} className='rounded' />
                      <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'><Shimmer width={120} height={25} className='rounded' /></h5>
                      <p className='lh-base'><Shimmer width={250} height={35} className='rounded' /></p>
                    </div>
                  </div>
                )

              })

                : ChooseData?.map((data, i) => {
                  return (
                    <div className='col-lg-4 col-sm-6 mb-3  col-sm-6' key={i}>
                      <div className='pshycal h-100'>
                        <img src={data?.icon} />
                        <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>{data?.title}</h5>
                        <p className='lh-base three_line' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                      </div>
                    </div>
                  )
                })

            }


            {/* <div className='col-lg-4 col-sm-6 mb-3 mb-3'>
              <div className='pshycal h-100'>
                <img src='/assets/iocns/sell.png' />
                <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>Sell anytime from home</h5>
                <p className='lh-base'>Sell anytime, without going anywhere and receive money direct in your account.</p>
              </div>
            </div> */}

          </div>
        </div>
      </section>

      <section>
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
            <div>
              <img src='/assets/images/shpas.png' />
              <h2 className='fw-bold'>Clients Say's?</h2>
            </div>
          </div>
        </div>


        {
          Isloading ? (
            <div className='says_c'>
              <div className="container">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    576: {
                      slidesPerView: 2
                    },
                    992: {
                      slidesPerView: 3
                    }
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination]}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="main_slider"
                >
                  {
                    Array.from({ length: 5 }).map((_, i) => {
                      return (
                        <SwiperSlide>
                          <div className='pshycal h-100 border' key={i}>
                            <div className='d-flex gap-2 align-items-center mb-3'>
                              <div className='border-circle'><Shimmer className='rounded' width={50} height={50} /></div>
                              <div>
                                <h5 className='fw-bold'><Shimmer className='rounded' width={60} height={20} /></h5>
                                <h6><Shimmer className='rounded' width={60} height={20} /></h6>
                              </div>
                            </div>
                            <p className='lh-base'><Shimmer className='rounded' width={300} height={70} /></p>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }


                </Swiper>
              </div>
            </div>
          ) : (

            <div className='says_c'>
              <div className="container">
                <Swiper
                  spaceBetween={10}
                  slidesPerView={3}
                  freeMode={true}
                  breakpoints={{
                    320: {
                      slidesPerView: 1
                    },
                    576: {
                      slidesPerView: 2
                    },
                    992: {
                      slidesPerView: 2
                    }
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[FreeMode, Pagination]}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                  className="main_slider"
                >
                  {
                    testimoanl_data.map((data, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <div className='pshycal h-100 border'>
                            <div className='d-flex gap-2 align-items-center mb-3'>
                              <img src={data?.user_image} className='rounded-circle' alt='' />
                              <div>
                                <h5 className='fw-bold'>{data?.user_name}</h5>
                                <h6>{data?.city_name}</h6>
                              </div>
                            </div>
                            <p className='lh-base four_line' >{data?.description}</p>
                          </div>
                        </SwiperSlide>
                      )
                    })
                  }
                </Swiper>
              </div>
            </div>

          )
        }

      </section>

      <FAQ />

      <section className='mt-3 trust' >
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-4'>
            <div>
              <img src='/assets/images/shpas.png' />
              <h2 className='fw-bold'>Trusted by the ones you trust</h2>
            </div>
          </div>

          <div className='row '>
            {
              Isloading ? Array.from({ length: 5 }).map((_, i) => {
                return (
                  <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
                    <div>
                      <Shimmer width={160} height={140} className='rounded' />
                    </div>
                  </div>
                )
              }) : Trustdata ? Trustdata?.map((data) => {
                return (
                  <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
                    <div>
                      <img src={data?.image} />
                    </div>
                  </div>

                )
              }) : ''
            }

          </div>
        </div>
      </section>

    </>
  )
}
