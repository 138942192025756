import React, { useCallback, useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useNavigate } from "react-router-dom";
import { Logindetail } from "../context/Logindetail.js";
import { Cancel_scheme, Getdigital_trans, PayDaily_Scheme, Post_PlanActive } from "../api/RouteScheme2.js";
import moment from "moment";
import toast from "react-hot-toast";
import useRazorpay from "react-razorpay";
import { Shimmer } from "react-shimmer";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
const Transaction = ({ settings }) => {

    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const { detail_info } = useContext(Logindetail)
    const [Data_list, setData_list] = useState([])
    const [ALlData_list, setALlData_list] = useState([])
    const [sort, setsort] = useState(5)
    const [PlanActive, setPlanActive] = useState()
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemaData, setshemaData] = useState()
    const [price, setprice] = useState()
    const [isloading, setisloading] = useState(true)
    // const [Entername, setEntername] = useState()
    const [spinner, setspinner] = useState(false)
    const [Transaction, setTranscation] = useState([])
    const [Transname ,setTransname] =useState()
    const [spinid ,setspinid] =useState()


    const getTransaction = async () => {
        Getdigital_trans()
            .then((res) => {
                setData_list(res?.data)
                setALlData_list(res?.data)
                setisloading(false)
            })
            .catch((err) => {
                console.log(err)
            })

    }

    const getactive = async (id) => {
        setspinner(true)
        document.getElementById('activepopmsg').click()
        setPlanActive(id)
        // Post_PlanActive(id)
        //     .then((res) => {
        //         document.getElementById('activepopmsg').click()
        //         getTransaction()
        //         setspinner(false)
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //         setspinner(false)
        //     })
    }

    console.log('transss' , Transaction)

    const handleactive = () => {
        Post_PlanActive(PlanActive)
            .then((res) => {
                getTransaction()
                setspinner(false)
                setPlanActive()
            })
            .catch((err) => {
                console.log(err)
                setspinner(false)
            })
    }


    const handlePayment = useCallback(async () => {
        setspinner(true)
        const options = {
            key: RAZAR_KEY,
            amount: (price) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": price,
                    "purchase_id": shemaData?.id,
                    "transaction_id": res?.razorpay_payment_id,
                }
                PayDaily_Scheme(body).then(res => {
                    getTransaction();
                    toast.success("Payment success!")
                    setShowRazorpayModal(false);
                    setspinner(false)

                }).catch(err => {
                    console.log('err', err.message)
                    setspinner(false)


                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemaData]);



    const openrazar_function = () => {
        handlePayment()
    }

    const router = useNavigate()


    const PostCancel_id = (id) => {

        Cancel_scheme(id)
        setspinner(true)
            .then((res) => {
                console.log(res)
                toast.success(res?.data)
                getTransaction()
                setspinner(false)
            })
            .catch((err) => {
                console.log(err)
                setspinner(false)
            })
    }

    const handlespinner = (data) => {
        setspinid(data?.id)
        setspinner(true)
    }

    const handledownload = (value) => {
        const baseurl = process.env.REACT_APP_BASE_URL
        let fileName = `${moment(value?.created_at).format('DD-MM-YYYY')}-invoice.pdf`;
        const url = `${baseurl}digital_invoice?id=${value?.purchase_id}`;
        fetch(url)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                setspinner(false)
                setspinid()
            })
            .catch(error => {
                console.error('Error fetching PDF: ', error);
                window.open(url, "_blank");
                setspinner(false)
                setspinid()
            });
    }



    // const handlesearch = () => {
    //     const name = Entername?.toLowerCase();
    //     const filtrer = ALlData_list.filter(e => e.beneficiary_id ? e.beneficiary?.name?.toLowerCase().includes(name) : detail_info?.name?.toLowerCase().includes(name));
    //     setData_list(filtrer)
    // }

    const columns = [

        { field: 's_no', headerName: 'S.NO', width: 70 },
        {
            field: 'name',
            headerName: 'Name',
            width: 120,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${row?.beneficiary?.name ? row?.beneficiary?.name : detail_info?.name}`,
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 120,
            valueGetter: (value, row) => `${row?.total_gram ? row?.total_gram : '0'}(gm)`,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            valueGetter: (value, row) => `${row?.total_amount ? `₹${row?.total_amount}` : '-'}`,
        },
        {
            field: 'purchase_Date',
            headerName: 'Purchase Date',
            width: 120,
            valueGetter: (value, row) => `${moment(row?.created_at).format('DD-MM-YYYY')}`,
        },
        {
            field: 'withdraw_date',
            headerName: 'Withdraw Date',
            width: 100,
            valueGetter: (value, row) => `${row?.withdraw_date ? row?.withdraw_date : '-'}`,
            sortable: false,
        },
        {
            field: 'view',
            headerName: 'View',
            width: '70',
            sortable: false,
            renderCell: (params) => (
                <>
                    <img
                        src="\assets\iocns\eye.png"
                        // onClick={() => params.row?.cancel_status == 1 ? {} : setTranscation(params.row?.purchase_transaction)}
                        alt=""
                        width={20}
                        style={{ cursor: 'pointer' }}
                        onClick={() => params.row?.cancel_status == 1 ? {} : handleTransaction(params?.row) }

                    />
                </>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: (params) => (
                <>
                    {
                        params?.row?.status === 1 ? <button className="btn bg-success  gold-activebtn text-white  border-0" disabled>Completed</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-secondary gold-activebtn text-white  border-0" disabled>InActive</button> : params?.row?.plan_active === 1 ? <button className="btn border-0 bg-primary text-white gold-activebtn" disabled={true}>Actived</button> : Math.floor(parseInt(params?.row?.total_gram)) >= 1 ? <button className="btn bg-warning gold-activebtn border-0  text-white" onClick={() => { getactive(params?.row?.id) }}>Active</button> : <button className="btn bg-secondary  gold-activebtn text-white  border-0" disabled>InActive</button>

                    }

                </>
            ),
            sortable: false,
        },
        {
            field: 'payment',
            headerName: 'Payment',
            width: 120,
            renderCell: (params) => (
                <>
                    {params?.row?.cancel_status === 1 || params?.row?.status === 1 ? <button className="btn  paynsd2  border-0 text-white" >PAY NOW</button> : <button className="btn paynsd text-white" onClick={() => { setshemaData(params?.row); document.getElementById('openPriceplanpopu').click() }}>PAY NOW</button>}
                </>
            ),
            sortable: false,
        },
        {
            field: 'cancel',
            headerName: 'Cancel',
            width: 120,
            renderCell: (params) => (
                <>

                    {
                        params?.row?.status === 1 ? <button className="btn bg-secondary  gold-cancellbtn border-0 text-white" disabled={true} >Cancel</button> : params?.row?.cancel_status === 1 ? <button className="btn bg-danger border-0 text-white gold-cancellbtn" disabled={true}>Cancelled </button> : moment(params?.row?.created_at, 'YYYY-MM-DD').diff(new Date(), 'days').toString().split('-')[1] >= 90 ? <button className="btn bg-danger border-0 gold-cancellbtn text-white" onClick={() => { PostCancel_id(params?.row?.id) }} >Cancel</button> :
                            <button className="btn bg-secondary gold-cancellbtn border-0 text-white" disabled={true}  >Cancel</button>

                    }
                </>
            ),
            sortable: false,
        }
    ];

    const columns2 = [

        { field: 's_no', headerName: 'S.NO', width: 70 },
        {
            field: 'name',
            headerName: 'Name',
            width: 120,
            // valueGetter: (value, row) => `${row.beneficiary_id ? row?.beneficiary?.name ?? '-' : detail_info?.name}`,
            valueGetter: (value, row) => `${Transname}`,
        },
        {
            field: 'weight',
            headerName: 'Weight',
            width: 120,
            valueGetter: (value, row) => `${row?.gold_gram ? row?.gold_gram : '0'}(gm)`,
        },
        {
            field: 'total',
            headerName: 'Total',
            width: 100,
            valueGetter: (value, row) => `${row?.amount ? `₹${row?.amount}` : '-'}`,
        },
        {
            field: 'paid_Date',
            headerName: 'Paid Date',
            width: 120,
            valueGetter: (value, row) => `${row?.paid_date ? moment(row?.paid_date).format('DD-MM-YYYY') : '-'}`,
        },
        {
            field : 'transaction_id',
            headerName :'Transaction ID',
            width:235,
            valueGetter:(value , row) =>`${row?.transaction_id ? row?.transaction_id : '-'}`

        },

        {
            field: 'invoice',
            headerName: 'Invoice',
            width: 90,
            renderCell: (params) => (
                <>
                    {
                        spinner && params?.row?.id == spinid ? (<span className="spinner-border spinner-border-sm mx-2 " role="status"></span>) : (<img src="\assets\iocns\invoice.png" onClick={() => { handledownload(params?.row); handlespinner(params?.row) }} className="ytjsfvdjd" />)

                    }
                </>
            ),
            sortable: false,
        }
    ];


    const paginationModel = { page: 0, pageSize: 10 };

    const handleTransaction =(value)=>{
       setTranscation(value?.digital_transaction)
       setTransname(value?.beneficiary?.name ? value?.beneficiary?.name : detail_info?.name )
    }


    // useEffect(() => {
    //     if (Entername) {
    //         handlesearch();
    //     } else {
    //         setData_list(ALlData_list);
    //     }

    // }, [Entername])

    useEffect(() => {
        getTransaction()
    }, [])

    return (
        <>
            <HeadingBanner title={isloading ? 'Loading...' : settings ? settings[0]?.digital_plan : 'Scheme'} />
            <section>

                <div className="container">
                    <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

                    <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="razarpaypopupLabel">{shemaData?.digital_plan?.name}</h1>

                                    <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={() => { document.getElementById('Rupees').value = null; setprice(null) }}></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (!price) {
                                            toast.error("Please Enter Price!")
                                        } else
                                            if (parseInt(price) < parseInt(shemaData?.digital_plan?.min_price)) {
                                                toast.error(`Minimum Amount is ${shemaData?.digital_plan?.min_price}!`)
                                            } else if (parseInt(price) > shemaData?.digital_plan?.max_price) {
                                                toast.error(`Maximum Amount is ${shemaData?.digital_plan?.max_price}!`)
                                            } else {
                                                openrazar_function()
                                                document.getElementById('closepriceModal')?.click();
                                                document.getElementById('Rupees').value = null
                                            }
                                    }}>
                                        <div className="mb-3">
                                            <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemaData?.digital_plan?.min_price} to Maximum Amount ${shemaData?.digital_plan?.max_price})`}</small></label>
                                            <input type="text" maxLength={10} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemaData?.digital_plan?.min_price}`} />
                                        </div>
                                        <div className="mb-3">
                                            <div className="update-profile  mx-2">
                                                <button className="text-white" type="submit">Continue</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="siptrans-layout">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-8 col-lg-8">
                                <div className="siptrans-content">
                                    <div className="siptrans-head pb-3">
                                        {
                                            isloading ? <h4><Shimmer className="rounded " width={180} height={30} /></h4> :
                                                <h4> {Transaction?.length > 0 ? <img src="/assets/iocns/Back.png" className="cursor_pointer" onClick={() => setTranscation([])} style={{ width: '30px' }} /> : ''} {Data_list[0]?.digital_plan?.name ? Data_list[0]?.digital_plan?.name : ''}</h4>
                                        }
                                    </div>
                                    {
                                        isloading ? (
                                            <div className="siptrans-table border" >
                                                <div className="siptable-view">
                                                    <table className="table">
                                                        <thead className="p-3">
                                                            <tr className="text-center">
                                                                <th scope="col" >S.NO</th>
                                                                <th scope="col" >Name</th>
                                                                <th scope="col">Weight</th>
                                                                <th scope="col">Total</th>
                                                                <th scope="col">Purchase</th>
                                                                <th scope="col">Withdraw</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Payment</th>
                                                                <th scope="col">Cancel</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {
                                                                Array.from({ length: 6 }).map((_, i) => {
                                                                    return (
                                                                        <tr className="text-center" key={i}>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block ms-2" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>
                                                                            <td className="text-center"><Shimmer height={25} width={50} className="rounded d-inline-block" /></td>

                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        ) :


                                            Transaction.length > 0 ? (
                                                <Paper sx={{ height: 700, width: '100%' }} className="tabledata">
                                                    <DataGrid
                                                        rows={Transaction.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                        columns={columns2}
                                                        initialState={{ pagination: { paginationModel } }}
                                                        pageSizeOptions={[5, 10]}
                                                        sx={{ border: 0 }}
                                                        disableColumnFilter
                                                        disableColumnMenu
                                                        disableColumnSelector
                                                        disableDensitySelector
                                                        className="schemedata-table"
                                                        slots={{ toolbar: GridToolbar }}
                                                        slotProps={{
                                                            toolbar: {
                                                                showQuickFilter: true,
                                                                printOptions: { disableToolbarButton: true },
                                                                csvOptions: { disableToolbarButton: true },

                                                            },
                                                        }}

                                                    />
                                                </Paper>
                                            ) :





                                                ALlData_list?.length > 0 ? (

                                                    <>
                                                        <Paper sx={{ height: 700, width: '100%' }} className="tabledata">
                                                            <DataGrid
                                                                rows={Data_list.map((item, index) => ({ s_no: index + 1, ...item }))}
                                                                columns={columns}
                                                                initialState={{ pagination: { paginationModel } }}
                                                                pageSizeOptions={[5, 10]}
                                                                sx={{ border: 0 }}
                                                                disableColumnFilter
                                                                disableColumnMenu
                                                                disableColumnSelector
                                                                disableDensitySelector
                                                                className="schemedata-table"
                                                                slots={{ toolbar: GridToolbar }}
                                                                slotProps={{
                                                                    toolbar: {
                                                                        showQuickFilter: true,
                                                                        printOptions: { disableToolbarButton: true },
                                                                        csvOptions: { disableToolbarButton: true },

                                                                    },
                                                                }}

                                                            />
                                                        </Paper>
                                                    </>
                                                )

                                                    : (<div className="addbank-ac text-center border py-3">
                                                        <div className="bankimage my-3  ">
                                                            <img src="\assets\iocns\pure gold.png" className="img-fluid rounded p-1 " style={{ border: "1px solid #991313" }} />
                                                        </div>
                                                        <div className="addbank-info ">
                                                            <h5>{settings ? settings[0]?.digital_plan : ''}</h5>
                                                        </div>
                                                        <div className="addbank-btn text-white my-3">
                                                            <button className="text-white" onClick={() => { router('/scheme') }} data-bs-whatever="@getbootstrap" >Buy Scheme</button>
                                                        </div>
                                                    </div>)

                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="modal fade" id="activeboxmsg" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <button className="d-none" data-toggle="modal" data-target="#activeboxmsg" id="activepopmsg"></button>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header poptitle justify-content-center border-0">
                            <h5 className="modal-title" id="exampleModalLongTitle">{settings ? settings[0]?.digital_plan : ''}</h5>
                        </div>
                        <div className="modal-body  text-center">
                            <div className="popactive-img">
                                <img src="\assets\iocns\Tick.png" />
                            </div>
                            <div className="pop-submsg mt-3">
                                <h4>The plan is currently active</h4>
                                <h4>you may only cancel it after 90 days.</h4>
                            </div>
                            <div className="modal-footer border-0 justify-content-center ">
                                <button type="button " className="btn btn-success" data-dismiss="modal" onClick={handleactive}>Active</button>
                                <button type="button " className="btn btn-danger" data-dismiss="modal">Close</button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Transaction;