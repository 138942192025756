import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../header/Headers.jsx';
import Footer from '../footer/Footer.jsx';
import { baseurl, setting } from '../api/Pageapi.js';
import axios from 'axios';

export default function Mainroutes({setting_data,openSchemes,footerpages}) { 

  return (
    <div> 
        <Header openScheme={openSchemes} setting_datas={setting_data}/>
        <div className='body_top'> 
            <Outlet/>
        </div>
        <Footer setting_datas={setting_data} footerpages={footerpages}  />
    </div>
  )
}
