import axios from 'axios'
import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import { fetchBeneficiaries, getSavingPlan_fun, postSchemaSave } from '../api/Pageapi';
import useRazorpay from "react-razorpay";
import toast from 'react-hot-toast';
import { Logindetail } from '../context/Logindetail';
import { useNavigate } from 'react-router-dom';
import { Shimmer } from 'react-shimmer';
import { Digitalscheme_fun, GetPlanbenefit, Savedigitalplan } from '../api/RouteScheme2';
import { GoldPlant_fun, SaveGoldplan } from '../api/RouteScheme3';
import { Footerpages } from '../context/Footerpages';
import { schemeplans } from '../api/Controllapi';




export default function Schemes({ setopenSchemes }) {

    const router = useNavigate();

    const RAZAR_KEY = process.env.REACT_APP_RAZAR_KEY;


    const { token, detail_info, setdetail_info } = useContext(Logindetail)
    const [scheme_list, setscheme_list] = useState({});
    const [Razorpay, isLoaded] = useRazorpay();
    const [showRazorpayModal, setShowRazorpayModal] = useState(false);
    const [shemData, setSchemaData] = useState()
    const [price, setprice] = useState();
    const [isLoading, setisLoading] = useState(true);
    const [benfic, setbenfic] = useState([]);
    const [DigitaScheme, setDegitalScheme] = useState({})
    const [planbenifit, setplanbenifit] = useState()
    const [BenifitAmt, setBenifitAmt] = useState()
    const [Goldplant_data, setGoldplant_data] = useState()
    const [checked, setchecked] = useState(false)
    const { footerpage } = useContext(Footerpages)
    const fromsubmit = useRef()
    const [ChargeAmt ,setChargeAmt] =useState()
    const [Nameid ,setNameid]=useState()


    const logout = () => {
        localStorage.removeItem("green_login")
        localStorage.removeItem("green_token")
        localStorage.removeItem("kyc")
        router('/');
        setdetail_info();
    }
    // const savingplan = async () => {
    //     getSavingPlan_fun()
    //         .then((res) => {
    //             setscheme_list(res?.data)
    //             setisLoading(false)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //             setisLoading(false)
    //         })
    // }

    // const digitalplan = () => {
    //     Digitalscheme_fun()
    //         .then((res) => {
    //             setDegitalScheme(res?.data)
    //             setisLoading(false)
    //         })
    //         .catch((err) => {
    //             setisLoading(false)
    //         })
    // }


    // const goldplant = () => {
    //     GoldPlant_fun()
    //         .then((res) => {
    //             setGoldplant_data(res?.data)
    //             setisLoading(false)
    //         })
    //         .catch((err) => {
    //             setisLoading(false)
    //         })
    // }

    const handlefectch = () => {
        fetchBeneficiaries()
            .then(res => {
                setbenfic(res?.data)
                setisLoading(false);
                if (res?.data?.status == 403) {
                    logout();
                }
            }).catch(err => {
                console.log('err', err.message);
                setisLoading(false)
            });
    }


    useEffect(() => {
        if (detail_info && token) {
            handlefectch();
        }
    }, [token]);

    // useEffect(() => {
    //     // savingplan();
    //     // digitalplan();
    //     // goldplant();
    // }, [])


    // 1 plan
    const handlePayment = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (price) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "amount": price,
                    "saving_plan_id": shemData?.id,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': Nameid,
                }
                postSchemaSave(body, token).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },

            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 2 plan

    const handlePaymentDigital = useCallback(async () => {

        const options = {
            key: RAZAR_KEY,
            amount: (price) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "digital_plan_id": shemData?.id,
                    "amount": price,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': Nameid
                }
                Savedigitalplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [price, shemData]);

    // 3 plan
    const handlePaymentgoldplant = useCallback(async (amount , enterprice) => {
        const options = {
            key: RAZAR_KEY,
            amount: (amount) * 100,
            currency: "INR",
            name: 'Green Heap',
            description: 'Green Heap',
            image: "/assets/iocns/__Fav Icon.png",
            handler: (res) => {
                const body = {
                    "gold_plant_id": shemData?.id,
                    "amount": enterprice,
                    "transaction_id": res?.razorpay_payment_id,
                    'benificiar': Nameid
                }
                SaveGoldplan(body).then(res => {
                    if (res?.error) {
                        return toast.error(res?.error ?? 'Somthing Went Wrong!')
                    }
                    router('/user/dashboard')
                    toast.success(`Payment successful from ${shemData?.name}`)
                    setShowRazorpayModal(false);
                }).catch(err => {
                    console.log('err', err.message)
                })
            },
            theme: {
                color: '#650020',
            },
        };

        const rzpay = new Razorpay(options);

        rzpay.open();

        // Reset the showRazorpayModal state after payment completion
        rzpay.on('payment.failed', function (response) {
            setShowRazorpayModal(false);
            console.error(response.error.description);
            toast.error(response.error.description)
        });

    }, [ChargeAmt, shemData]);


    const handlepayModal = (amount , enterprice) => {
        if (shemData?.plan_title === "saving_plan") {
            handlePayment()
        }
        if (shemData?.plan_title === "digital_plan") {
            handlePaymentDigital()
        }
        if (shemData?.plan_title === "gold_plant") {
            handlePaymentgoldplant(amount , enterprice);
        }
    }

    const schemebeniftapi = () => {

        GetPlanbenefit()
            .then((res) => {
                setplanbenifit(res?.months)
                setBenifitAmt(res?.amount)
                setisLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getscheme_plans =()=>{
        schemeplans()
        .then((res)=>{
            setscheme_list(res?.data[0])
            setDegitalScheme(res?.data[1])
            setGoldplant_data(res?.data[2])

        })
        .catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        // schemebeniftapi()
        getscheme_plans()
    }, [])


    return (<>
        <section>
            <button type="button" className="d-none" data-bs-toggle="modal" id='openPriceplanpopu' data-bs-target="#razarpaypopup"> sssdsdsd</button>

            <div className="modal fade" id="razarpaypopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="razarpaypopupLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className={shemData?.plan_title === "gold_plant" ? 'modal-content paybox' : 'modal-content' }>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="razarpaypopupLabel">{shemData?.title ?? shemData?.name}</h1>

                            <button type="button" className="btn-close" id='closepriceModal' data-bs-dismiss="modal" aria-label="Close" onClick={()=>{document.getElementById('Rupees').value=null ; setprice(null)}} ></button>
                        </div>
                        <div className="modal-body">
                            <form ref={fromsubmit} onSubmit={(e) => {
                                e.preventDefault();

                                if (!price) {
                                    toast.error("Please Enter Price!")
                                } else if (shemData?.plan_title === "gold_plant") {
                                    const amount = parseFloat(price) + parseFloat(((price * 6 / 100) + (price * 3 / 100)))
                                  
                                    if (parseInt(price) < parseInt(shemData?.min_price)) {
                                        toast.error(`Minmum Amount is ${shemData?.min_price}!`)
                                    } else if (parseInt(price) > shemData?.max_price) {
                                        toast.error(`Maximum Amount is ${shemData?.max_price}!`)
                                    } else if (checked !== true) {
                                        toast.error('Please Agree Terms & conditions')
                                    }
                                    else {
                                       
                                        setShowRazorpayModal(true);
                                        handlepayModal(amount , price);
                                        document.getElementById('closepriceModal')?.click();
                                        document.getElementById('Rupees').value=null                                       
                                             
                                    }
                                } else {
                                    if (parseInt(price) < parseInt(shemData?.min_price)) {
                                        toast.error(`Minmum Amount is ${shemData?.min_price}!`)
                                    } else if (parseInt(price) > shemData?.max_price) {
                                        toast.error(`Maximum Amount is ${shemData?.max_price}!`)
                                    } else if (checked !== true) {
                                        toast.error('Please Agree Terms & conditions')
                                    }
                                    else {
                                        setShowRazorpayModal(true);
                                        handlepayModal();
                                        document.getElementById('closepriceModal')?.click();
                                        document.getElementById('Rupees').value=null
                                        
                                    }
                                }

                            }}>
                                <div className="mb-3">
                                    <label className="col-form-label fw-bold">Beneficiaries</label>
                                    <select className="form-select p-3" onChange={(e) => setNameid(e.target.value)} aria-label="ben" >
                                        <option value={''} selected>{detail_info?.name}</option>
                                        {
                                            benfic.map((data, i) => (
                                                <option value={data?.id} key={i}>{data.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="Rupees" className="form-label"><span className='fw-bold'>Price</span> <small className='text-danger'>{`(Minimum Amount ${shemData?.min_price} to Maximum Amount ${shemData?.max_price})`}</small></label>
                                    <input type="text" maxLength={10} value={price} onChange={(e) => setprice(e.target.value?.replace(/[^0-9]/g, ""))} className="form-control p-3" id="Rupees" placeholder={`Ex: ${shemData?.min_price}`} />
                                    {/* {shemData?.plan_title === "gold_plant" && price ? <span className='text-color'>Deduct Gst & Service tax  : {(price - ((price * 6 / 100) + (price * 3 / 100))).toFixed(2)}</span> : ''} */}
                                    {shemData?.plan_title === "gold_plant"  ? (<><p className='text-color m-0'>VAT Tax : { price ? (price * 3 / 100).toFixed(2) : 0}</p> 
                                    <p className='text-color m-0'>Service charge : { price ? (price * 6 / 100).toFixed(2) : 0}</p>  
                                    <p className='text-color m-0'>VAT + Service : { price ?((price * 6 / 100) + (price * 3 / 100)).toFixed(2) : 0}</p>
                                    <p className='text-color m-0'>Total Amount : {  price ? ( parseFloat(price) + ((price * 6 / 100) + (price * 3 / 100))) : 0  }</p>
                                    </>)  : ''}
                                </div>
                                <div className="mb-3">
                                    <input className="form-check-input" type="checkbox" value="" id="agreecheck" onChange={(e) => {setchecked(e.target.checked)}} />
                                  
                                    <label className="form-check-label ms-2" htmlFor="flexCheckDefault">
                                        {/* I  agree {
                                            footerpage ? (<a href={`pages/${footerpage[0]?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>) : (<span className='termsborder'>Terms and Condition</span>)
                                        } */}
                                        I agree <a href={`pages/${shemData?.page?.slug}`} target='_blank'><span className='termsborder'>Terms and Condition</span></a>
                                    </label>
                                </div>
                                <div className="mb-3">
                                    <div className="update-profile  mx-2">
                                        <button className="text-white" type="submit">Continue</button>
                                    </div>
                                </div>
                            </form>
                        </div>
              
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                    <div>
                        <img src='/assets/images/shpas.png' />
                        <h2 className='fw-bold'>Quick overview of schemes</h2>
                    </div>
                </div>

                {isLoading ?
                    <div className=' row '>
                        {
                            Array.from({ length: 3 }).map((_, i) => (
                                <div className='col-md-6  col-lg-4 col-sm-6 mt-lg-0 mt-sm-3' key={i}>
                                    <div className='schema_main  '>
                                        <div className='top'>
                                            <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                            <h4 className=' fw-bold pb-2'> <Shimmer width={180} height={30} /></h4>
                                        </div>
                                        <div className='schema'>
                                            <div className='d-flex gap-2 align-items-center mb-3'>
                                                {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                <Shimmer width={250} height={22} className='rounded' />
                                            </div>
                                            <div className='d-flex gap-2 align-items-center mb-3'>
                                                {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                <Shimmer width={250} height={22} className='rounded' />
                                            </div>
                                            <div className='d-flex gap-2 align-items-center mb-3'>
                                                {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                <Shimmer width={250} height={22} className='rounded' />
                                            </div>
                                            <div className='d-flex gap-2 align-items-center mb-3'>
                                                {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                <Shimmer width={250} height={22} className='rounded' />
                                            </div>
                                            <div className='d-flex gap-2 align-items-center mb-3 '>
                                                {/* <img src='/assets/iocns/Tick.png' className='' />   */}
                                                <Shimmer width={250} height={22} className='rounded' />
                                            </div>

                                            <div className='schemebtn-3'>
                                                <Shimmer width={177} height={49} className='rounded-5' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    :
                    <div className=' row '>
                        {
                            scheme_list ?
                                <div className='col-md-6 col-lg-4 col-sm-6 chkclick'>
                                    <div className='schema_main' >
                                        <div className='top_active act1'>
                                            <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                            <h4 className=' fw-bold pb-2'>{scheme_list?.name}</h4>
                                        </div>
                                        <div className='schema schemelen'>
                                            {/* <div className=''>
                                                <p className='mb-0'  dangerouslySetInnerHTML={{__html: data?.description }}></p>
                                            </div> */}
                                            <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                <div className="d-flex gap-2  align-items-center">
                                                    <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                    <p className='mb-0'>Lock in Period </p>
                                                </div>

                                                <div>
                                                    <p className='mb-0'>{scheme_list?.plan_tenure ?? 0} Months</p>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                <div className="d-flex gap-2  align-items-center">
                                                    <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                    <p className='mb-0'>Punctuality Bonus</p>
                                                </div>

                                                <div>
                                                    <p className='mb-0'>{scheme_list?.punctuality_bonus ?? 0} %</p>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                <div className="d-flex gap-2  align-items-center">
                                                    <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                    <p className='mb-0'>Profit Rate </p>
                                                </div>

                                                <div>
                                                    <p className='mb-0'>{scheme_list?.bonus ?? 0} %</p>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                <div className="d-flex gap-2  align-items-center">
                                                    <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                    <p className='mb-0'>Minimum</p>
                                                </div>

                                                <div>
                                                    <p className='mb-0'>₹ {scheme_list?.min_price ?? 0}</p>
                                                </div>
                                            </div>

                                            <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                                <div className="d-flex gap-2  align-items-center">
                                                    <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                    <p className='mb-0'>Maximum</p>
                                                </div>

                                                <div>
                                                    <p className='mb-0'>₹ {scheme_list?.max_price ?? 0}</p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className='schema schemebtn-1'>
                                            <button type='button' onClick={() => {
                                                if (detail_info && token) {
                                                    setSchemaData(scheme_list);
                                                    setprice()
                                                    document.getElementById("openPriceplanpopu")?.click();
                                                } else {
                                                    setSchemaData(scheme_list);
                                                    setprice()
                                                    document.getElementById("loginPopupopen")?.click();
                                                    setopenSchemes(true);

                                                }
                                            }} className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                        {
                            DigitaScheme ? <div className='col-md-6 col-lg-4 col-sm-6 chkclick' >
                                <div className='schema_main'>
                                    <div className='top_active'>
                                        <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                        <h4 className=' fw-bold pb-2'>{DigitaScheme?.name}</h4>
                                    </div>
                                    <div className='schema schemelen'>
                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Lock in Period</p>
                                            </div>

                                            <div>
                                                {/* <p className='mb-0'>{DigitaScheme?.gold_approx ?? 0} %</p> */}
                                                <p className='mb-0'>3 Years</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Active Plan</p>
                                            </div>

                                            <div>
                                                {/* <p className='mb-0'>{parseFloat(DigitaScheme?.silver_bonus ?? 0)} %</p> */}
                                                <p className='mb-0'>1 (gm)</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Minimum</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>₹ {DigitaScheme?.min_price ?? 0}</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Maximum</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>₹ {DigitaScheme?.max_price ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' schema schemebtn-1'>
                                        <button type='button' onClick={() => {
                                            if (detail_info && token) {
                                                setSchemaData(DigitaScheme);
                                                document.getElementById("openPriceplanpopu")?.click();
                                            } else {
                                                setSchemaData(DigitaScheme);
                                                document.getElementById("loginPopupopen")?.click();
                                                setopenSchemes(true);
                                            }
                                        }} className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                        </button>
                                    </div>
                                </div>
                            </div> : ''
                        }

                        {
                            Goldplant_data ? <div className='col-md-6 col-lg-4 col-sm-6 chkclick' >
                                <div className='schema_main'>
                                    <div className='top_active'>
                                        <img alt='' src='/assets/images/green.png' className='log mb-3' />
                                        <h4 className=' fw-bold pb-2'>{Goldplant_data?.name}</h4>
                                    </div>
                                    <div className='schema schemelen'>
                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Principal Returns After</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>{Goldplant_data?.returns_year ?? 0} year</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Assured Returns</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>{Goldplant_data?.payout_term ?? 0} year</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Minimum</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>₹ {Goldplant_data?.min_price ?? 0}</p>
                                            </div>
                                        </div>

                                        <div className='d-flex gap-2 justify-content-between align-items-center mb-3'>
                                            <div className="d-flex gap-2  align-items-center">
                                                <img alt='' src='/assets/iocns/Tick.png' className='' />
                                                <p className='mb-0'>Maximum</p>
                                            </div>

                                            <div>
                                                <p className='mb-0'>₹ {Goldplant_data?.max_price ?? 0}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className=' schema schemebtn-1'>
                                        <button type='button' onClick={() => {
                                            if (detail_info && token) {
                                                setSchemaData(Goldplant_data);
                                                document.getElementById("openPriceplanpopu")?.click();
                                            } else {
                                                setSchemaData(Goldplant_data);
                                                document.getElementById("loginPopupopen")?.click();
                                                setopenSchemes(true);
                                            }
                                        }} className='btn d-flex align-items-center gap-2'>Buy scheme  <img alt='' src='/assets/iocns/Arrow right.png' className='' />
                                        </button>
                                    </div>
                                </div>
                            </div> : ''
                        }
                    </div>
                }
            </div>
        </section>

        {/* <section id="gold_scheme123"  >
            <section className='schema2 '>
                <div className='container'>
                    <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                        <div>
                            <img src='/assets/images/shpas.png' />
                            <h2 className='fw-bold'>Returns with Gold Investment Leaf (Digital Gold Plan)</h2>
                        </div>
                    </div>
                    <div className='mb-3'>
                        {
                            isLoading ? (
                            <div className='d-flex schematable gap-3'>
                                <div>
                                    <div className='mb-2 leaft text-center G1'>
                                        <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center  G2'>
                                        <h5>3 months (0-90 days)</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center G3'>
                                        <h5>6 months (90-180 days)</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center G4'>
                                        <h5>9 months (180 - 270 days)</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center G5'>
                                        <h5>12 months (270 - 360 days)</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center G6'>
                                        <h5>2nd Year</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center G7'>
                                        <h5>3rd Year</h5>
                                    </div>
                                </div>

                                <div>
                                    <div className='mb-2 leaft text-center C1'>
                                        <h5>GreenHeap Gold approx</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                </div>

                                <div>
                                    <div className='mb-2 leaft text-center C1'>
                                        <h5>GreenHeap Silver Bonus</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                </div>

                                <div>
                                    <div className='mb-2 leaft text-center C1'>
                                        <h5>GreenHeap Gold + Silver Bonus</h5>
                                    </div>


                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                </div>

                                <div>
                                    <div className='mb-2 leaft text-center F1'>
                                        <h5 className='text-light'>Sip Total Returns</h5>
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>

                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                    <div className='mb-2 leaft text-center  p-0 '>
                                        <Shimmer className='rounded w-100 h-100' />
                                    </div>
                                </div>

                            </div>) :
                                planbenifit ? (
                                    <div className='d-flex schematable gap-3'>
                                        <div>
                                            <div className='mb-2 leaft text-center G1'>
                                                <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center  G2'>
                                                <h5>3 months (0-90 days)</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center G3'>
                                                <h5>6 months (90-180 days)</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center G4'>
                                                <h5>9 months (180 - 270 days)</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center G5'>
                                                <h5>12 months (270 - 360 days)</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center G6'>
                                                <h5>2nd Year</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center G7'>
                                                <h5>3rd Year</h5>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='mb-2 leaft text-center C1'>
                                                <h5>GreenHeap Gold approx. ( {`Rs. ${BenifitAmt} `} )</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2 '>
                                                <h5> {`(${parseFloat(planbenifit?.three_months.gold) + '%' + 'X' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.six_months.gold) + '%' + 'X' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.nine_months.gold) + '%' + 'X' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.one_year.gold) + '%' + 'X' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.two_years.gold) + '%' + 'X' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.three_years.gold) + '%' + 'X' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='mb-2 leaft text-center C1'>
                                                <h5>GreenHeap Silver Bonus</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2 '>
                                                <h5> {`(${parseFloat(planbenifit?.three_months.silver) + '%' + 'X' + planbenifit?.three_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.six_months.silver) + '%' + 'X' + planbenifit?.six_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.nine_months.silver) + '%' + 'X' + planbenifit?.nine_months?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.one_year.silver) + '%' + 'X' + planbenifit?.one_year?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.two_years.silver) + '%' + 'X' + planbenifit?.two_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${parseFloat(planbenifit?.three_years.silver) + '%' + 'X' + planbenifit?.three_years?.months + 'm'})`} Rs. {Number((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)}</h5>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='mb-2 leaft text-center C1'>
                                                <h5>GreenHeap Gold + Silver Bonus</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5> {`(${Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2))}  </h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center C2'>
                                                <h5>{`(${Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2) + ' + ' + Number((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)})`} Rs. {parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='mb-2 leaft text-center F1'>
                                                <h5 className='text-light'>Sip Total Returns</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F2'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_months.gold / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.three_months.silver / 100) * planbenifit?.three_months?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F3'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.six_months.gold / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.six_months.silver / 100) * planbenifit?.six_months?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F4'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.nine_months.gold / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.nine_months.silver / 100) * planbenifit?.nine_months?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F5'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.one_year.gold / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.one_year.silver / 100) * planbenifit?.one_year?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F6'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.two_years.gold / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.two_years.silver / 100) * planbenifit?.two_years?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>

                                            <div className='mb-2 leaft text-center F7'>
                                                <h5>{parseFloat(Number((parseFloat(planbenifit?.three_years.gold / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2)) + parseFloat(Number((parseFloat(planbenifit?.three_years.silver / 100) * planbenifit?.three_years?.months) * BenifitAmt).toFixed(2))}</h5>
                                            </div>
                                        </div>

                                    </div>
                                ) : ''
                        }


                    </div>
                    <div className='text-center'>
                        <h5><span className='text-decoration-underline' style={{ cursor: "pointer" }} onClick={() => { router(`/pages/${footerpage[0]?.slug}`) }}>*Terms & Conditions</span> are apply</h5>
                    </div>
                </div>
            </section>
        </section> */}

<section className='schema2' >
            <div className='container'>
                <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
                    <div>
                        <img src='/assets/images/shpas.png' />
                        <h2 className='fw-bold'>Returns with Gold Investment Leaf (SIP Scheme)</h2>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='d-flex schematable gap-3'>
                        <div>
                            <div className='mb-2 leaft text-center G1'>
                                <h5 className='text-light'>Gold Investment Leaf Scheme</h5>
                            </div>

                            <div className='mb-2 leaft text-center  G2'>
                                <h5>3 months (0-90 days)</h5>
                            </div>

                            <div className='mb-2 leaft text-center G3'>
                                <h5>6 months (90-180 days)</h5>
                            </div>

                            <div className='mb-2 leaft text-center G4'>
                                <h5>9 months (180 - 270 days)</h5>
                            </div>

                            <div className='mb-2 leaft text-center G5'>
                                <h5>12 months (270 - 360 days)</h5>
                            </div>

                            <div className='mb-2 leaft text-center G6'>
                                <h5>2nd Year</h5>
                            </div>

                            <div className='mb-2 leaft text-center G7'>
                                <h5>3rd Year</h5>
                            </div>
                        </div>

                        <div>
                            <div className='mb-2 leaft text-center C1'>
                                <h5>GreenHeap Gold approx. (1,000 assured)</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2 '>
                                <h5>(0.25% x 3m) Rs. 7.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.50% x 6m) Rs. 30</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.75% x 9m) Rs. 67.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 12m) Rs. 120</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 24m) Rs. 240</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 36m) Rs. 360</h5>
                            </div>
                        </div>

                        <div>
                            <div className='mb-2 leaft text-center C1'>
                                <h5>GreenHeap Gold approx. (1,000 assured)</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.25% x 3m) Rs. 7.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.50% x 6m) Rs. 30</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.75% x 9m) Rs. 67.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 12m) Rs. 120</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 24m) Rs. 240</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 36m) Rs. 360</h5>
                            </div>
                        </div>

                        <div>
                            <div className='mb-2 leaft text-center C1'>
                                <h5>GreenHeap Gold approx. (1,000 assured)</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.25% x 3m) Rs. 7.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.50% x 6m) Rs. 30</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(0.75% x 9m) Rs. 67.5</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 12m) Rs. 120</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 24m) Rs. 240</h5>
                            </div>

                            <div className='mb-2 leaft text-center C2'>
                                <h5>(1% x 36m) Rs. 360</h5>
                            </div>
                        </div>

                        <div>
                            <div className='mb-2 leaft text-center F1'>
                                <h5 className='text-light'>Sip Total Returns</h5>
                            </div>

                            <div className='mb-2 leaft text-center F2'>
                                <h5>1031.50</h5>
                            </div>

                            <div className='mb-2 leaft text-center F3'>
                                <h5>1090.00</h5>
                            </div>

                            <div className='mb-2 leaft text-center F4'>
                                <h5>1157.50</h5>
                            </div>

                            <div className='mb-2 leaft text-center F5'>
                                <h5>1260.00</h5>
                            </div>

                            <div className='mb-2 leaft text-center F6'>
                                <h5>1600.00</h5>
                            </div>

                            <div className='mb-2 leaft text-center F7'>
                                <h5>2080.00</h5>
                            </div>
                        </div>

                    </div>

                </div>
                <div className='text-center'>
                        <h5><span className='text-decoration-underline' style={{ cursor: "pointer" }} onClick={() => { router(`/pages/${footerpage[0]?.slug}`) }}>*Terms & Conditions</span> are apply</h5>
                    </div>
            </div>
        </section>
    </>
    )
}

