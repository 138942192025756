import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination, Grid } from "swiper/modules";
import Schemes from '../Components/Schemes.jsx';
import FAQ from '../Components/FAQ.jsx';
import Chart from '../Components/Chart.jsx';
import { Digitalgold_fun, Enroll_fun, homebannerapi, Investing_fun, whychoose } from '../api/Pageapi.js';
import { Liveprice } from '../Components/Liveprice.jsx';
import { Shimmer } from 'react-shimmer';



export default function Home({ setopenScheme }) {


  const [homebanner, sethomebanner] = useState([])
  const [Isloading, setIsloading] = useState(true)
  const [ChooseData, setChooseData] = useState()
  const [Enroll_data, setEnroll_data] = useState()
  const [Investing_data, setInvesting_data] = useState()
  const [Digitalgold_data, setDigitalgold_data] = useState()

  const gethomebannerapi = async () => {


    homebannerapi()
      .then((res) => {
        sethomebanner(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })

  }

  const getWhyChoose = () => {
    whychoose()
      .then((res) => {
        setChooseData(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getenroll = () => {
    Enroll_fun()
      .then((res) => {
        setEnroll_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getinvesting = () => {
    Investing_fun()
      .then((res) => {
        setInvesting_data(res?.data)
        setIsloading(false)
      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }

  const getdigitalgold = () => {
    Digitalgold_fun()
      .then((res) => {
        setDigitalgold_data(res?.data)
        setIsloading(false)

      })
      .catch((err) => {
        console.log(err)
        setIsloading(true)
      })
  }



  // eslint-disable-next-line react-hooks/exhaustive-deps
  // useEffect( async() => { 
  //   await Promise.all([
  //     gethomebannerapi(),
  //     getWhyChoose(),
  //     // getenroll(),
  //     getinvesting(),
  //     getdigitalgold()
  //   ])
  // }, [])   

  const fetchData =  async() => {
    await Promise.all([
      gethomebannerapi(),
      getWhyChoose(),
      // getenroll(),
      getinvesting(),
      getdigitalgold()
    ])
  }

  useEffect(() => {
    fetchData()
  },[])



  return (
    <>
      {
        Isloading ? (<div>
          <Swiper
            spaceBetween={10}
            slidesPerView={1}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            modules={[FreeMode, Pagination]}
            className="main_slider"
          >
            {
              Array.from({ length: 3 }).map((_, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div><Shimmer height={600} className='w-100' />  </div>
                  </SwiperSlide>
                )
              })
            }
          </Swiper>

        </div>)

          : homebanner ? (
            <section className='home_slider'>
              <Swiper
                spaceBetween={10}
                slidesPerView={1}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                className="main_slider"
              >
                {
                  homebanner.map((res, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div>
                          <img alt='' src={res.image} className='w-100' />
                        </div>
                      </SwiperSlide>

                    )
                  })
                }
              </Swiper>

              <Liveprice />
            </section>

          ) : ''
      }



      <section className='schemebar'>
        <Schemes setopenSchemes={setopenScheme} />
      </section>

      <section >
        <div className='container smallBan'>
          <div className='row  '>

            {
              Isloading ? Array.from({ length: 4 }).map((_, i) => {

                return (

                  <div className='col-lg-3 col-md-4 col-sm-6  col-6 mb-2 '>
                    <Shimmer className='rounded w-100' height={350} />
                  </div>
                )

              }) : Enroll_data ? Enroll_data.map((data) => {
                return (
                  <div className='col-lg-3 col-md-4 col-sm-6  col-6 mb-2 '>
                    <img alt='' src={data?.image} className='' />
                  </div>
                )
              }) : ''
            }

            {/* 
            <div className='col-lg-3 col-md-4 col-sm-6  col-6 mb-2 '>
              <img alt='' src='/assets/images/bb.png' className='' />
            </div> */}
          </div>
        </div>
      </section>

      <Chart />


      {/* 
      <section>
        <div className='container'>
          <div className='wealth_smart d-md-flex align-items-center justify-content-between d-sm-block'>
            <div className='d-flex gap-3 align-items-center'>
              <img alt='' src='/assets/iocns/Growth.png' />
              <div>
                <h2>Grow your wealth smarter</h2>
                <h5>Start an SIP to invest in gold every month.</h5>
              </div>
            </div>
            <div>
              <button type='button' className='btn d-flex align-items-center mt-3 mt-md-0 gap-2'>Start investing <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button>
            </div>

          </div>
        </div>
      </section> */}

      <section>
        <div className='container'>
          <div className='wealth_smart d-md-flex align-items-center justify-content-between d-sm-block'>
            {
              Isloading ? (<>

                <div className='d-flex gap-3 align-items-center'>
                  <Shimmer width={60} height={60} className='rounded' />
                  <div>
                    <h2><Shimmer width={150} height={20} className='rounded' /></h2>
                    <h5><Shimmer width={250} height={40} className='rounded' /></h5>
                  </div>
                </div>
                <div>
                  <div><Shimmer width={200} height={50} className='rounded' /> </div>
                </div>
              </>) :

                Investing_data ? (
                  <>
                    <div className='d-flex gap-3 align-items-center'>
                      <img alt='' src={Investing_data?.image} />
                      <div>
                        <h2>{Investing_data?.title}</h2>
                        <h5>{Investing_data?.subtitle}</h5>
                      </div>
                    </div>
                    <div>
                      <a href={Investing_data?.button_url} target='_blank'><button type='button' className='btn d-flex align-items-center mt-3 mt-md-0 gap-2'>{Investing_data?.button_name} <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button></a>
                    </div>
                  </>

                ) : ""
            }

          </div>
        </div>
      </section>

      <section className='schema2 position-relative' >
        <div className='container'>
          <div className='d-flex justify-content-center w-100 text-center titsksd mb-3'>
            <div>
              <img alt='' src='/assets/images/shpas.png' />
              <h2 className='fw-bold'>Why choose digital gold </h2> <h2 className='fw-bold'> Over physical gold?</h2>
            </div>
          </div>

          <div className='row'>


            <div className='col-xl-3 col-lg-6 col-sm-6 col-sm-6 mb-3 activescheme'>
              <div className='sffsfsf'>
                <img alt='' src='/assets/images/physical.png' className='' />
              </div>
            </div>

            {
              Isloading ? Array.from({ length: 3 }).map((_, i) => {

                return (
                  <div className='col-lg-3 col-sm-6 mb-3 mb-3' key={i}>
                    <div className='pshycal h-100'>
                      <Shimmer width={70} height={60} className='rounded' />
                      <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'><Shimmer width={120} height={25} className='rounded' /></h5>
                      <p className='lh-base'><Shimmer width={250} height={35} className='rounded' /></p>
                    </div>
                  </div>
                )

              })

                : ChooseData ? ChooseData?.slice(0, 3)?.map((data, i) => (
                  < div className='col-xl-3 col-lg-6 col-sm-6 mb-3 col-12' key={i}>
                    <div className='pshycal h-100'>
                      <img alt='' src={data?.icon} />
                      <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>{data?.title}</h5>
                      <p className='lh-base three_line' dangerouslySetInnerHTML={{ __html: data?.description }}></p>
                    </div>
                  </div>
                )
                ) : ''

            }

            {/* <div className='col-xl-3 col-lg-6 col-sm-6 mb-3 col-12'>
              <div className='pshycal h-100'>
                <img alt='' src='/assets/iocns/Guaranteed.png' />
                <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>Guaranteed 24K Gold</h5>
                <p className='lh-base'>Unlike local vendors, with SafeGold, you directly buy from the manufacturer</p>
              </div>
            </div>

            <div className='col-xl-3  col-lg-6 col-sm-6 col-12 mb-3'>
              <div className='pshycal h-100'>
                <img alt='' src='/assets/iocns/sell.png' />
                <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>Sell anytime from home</h5>
                <p className='lh-base'>Sell anytime, without going anywhere and receive money direct in your account.</p>
              </div>
            </div>
            
            <div className='col-xl-3 col-lg-6 col-sm-6 col-12 mb-3  '>
              <div className='pshycal h-100'>
                <img alt='' src='/assets/iocns/Earn.png' />
                <h5 className='text-wrap fw-bold mt-2 w-md-50 w-sm-100'>Earn income on gold</h5>
                <p className='lh-base'>You can lend digital gold to SafeGold-verified borrowers and earn monthly rental income in the form of gold.</p>
              </div>
            </div> */}


          </div>
        </div>
      </section>

      <section className='covert'>
        <div className='container'>
          <div className='row'>
            {
              Isloading ? (<>
                <div className='col-xl-5 col-lg-6'>
                  <h3 className='text-wrap  lh-lg fw-bold mb-3' ><Shimmer className='rounded w-75 h-75' /></h3>
                  <h5 className='text-wrap mb-3' ><Shimmer width={300} height={30} className='rounded' /></h5>
                  <p className='lh-base mb-3'><Shimmer width={400} height={150} className='rounded' /></p>

                  <div><Shimmer width={160} height={50} className='rounded' /></div>
                </div>

                <div className='col-xl-7 col-lg-6 d-flex justify-content-center'>
                  <div className='d-flex justify-content-center w-100'>
                    <Shimmer className='rounded w-100 h-100' />
                  </div>
                </div>
              </>) : Digitalgold_data ?
                (<> <div className='col-xl-5 col-lg-6'>
                  <h3 className='text-wrap  lh-lg fw-bold mb-3' >{Digitalgold_data?.title}</h3>
                  <h5 className='text-wrap mb-3' >{Digitalgold_data?.subtitle}</h5>
                  <p className='lh-base mb-3'>{Digitalgold_data?.description}</p>

                  <a href={Digitalgold_data?.button_url} target='_blank'><button type='button' className='btn d-flex align-items-center gap-2'>{Digitalgold_data?.button_name} <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button></a>
                </div>

                  <div className='col-xl-7 col-lg-6 d-flex justify-content-center'>
                    <div className='d-flex justify-content-center w-100'>
                      <img alt='' src={Digitalgold_data?.image} className='img-fluid' />
                    </div>
                  </div></>) : ''
            }
          </div>
        </div>
      </section>

      {/* <section className='covert'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-5 col-lg-6'>
              <h3 className='text-wrap  lh-lg fw-bold mb-3' >How can customers convert digital to physical gold & Silver?</h3>
              <h5 className='text-wrap mb-3' >24K Gold / Silver Coins & Bars delivered to your doorstep</h5>
              <p className='lh-base mb-3'>Convert your digital gold to physical gold by paying a nominal minting charge. Your delivery comes with free insurance, to ensure your coins and bars reach you safely.</p>

              <button type='button' className='btn d-flex align-items-center gap-2'>Buy Gold <img alt='' src='/assets/iocns/Arrow right.png' className='smalImg' /> </button>
            </div>

            <div className='col-xl-7 col-lg-6 d-flex justify-content-center'>
              <div className='d-flex justify-content-center w-100'>
                <img alt='' src='/assets/images/gold silver.png' className='img-fluid' />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <FAQ />



    </>
  )
}
