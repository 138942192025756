import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import * as Yup from 'yup'
import { useFormik } from "formik";
import { Logindetail } from "../context/Logindetail.js";
import { bankdetail, baseurl, getBankDetail } from "../api/Pageapi.js";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Shimmer } from "react-shimmer";
import toast from "react-hot-toast";
import { accountdetails_fun } from "../api/Dashboardapi.js";

const MyBankAccount = ({settings}) => {
    const [spinner, setspinner] = useState(false)
    const [acc_list, setacc_list] = useState({})
    const [Isloading, setIsLoading] = useState(true)
    const [bankaccount, setbankaccount] = useState({
        "holder_name": '',
        "account_number": '',
        "confirm_account_number": '',
        "ifsc": '',
        "account_type": ''
    })

    console.log("bankaccountbankaccount", bankaccount);


    const updateschema = Yup.object().shape({
        "holder_name": Yup.string().required('Enter Account Holder Name'),

        "account_number": Yup.string()
            .required("Enter Account Number")
            .max(12, 'Account number Must be 12 digits'),

        "confirm_account_number": Yup.string().required('Enter Confirm Account Number')
            .oneOf([Yup.ref('account_number'), null], 'Account Number must be a Same')
            .max(12, 'Account number Must be 12 digits'),
            

        "ifsc": Yup.string().required('Enter IFSC Number'),

        "account_type": Yup.string().required('Enter Account Number'),
    })

    const { token } = useContext(Logindetail)


    const Formik = useFormik({
        initialValues: bankaccount,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            setspinner(true)
            accountdetails_fun(value, token)
                .then((res) => {
                    console.log(res)
                    document.getElementById('clode_accountmodal')?.click()
                    toast.success(res?.message)
                    getAccountDetail()
                    resetForm()
                    setspinner(false)
                })
                .catch((err) => {
                    console.log(err)
                    setspinner(false)

                })
        }
    });



    const getAccountDetail = async () => {
        getBankDetail(token)
            .then((res) => {
                setacc_list(res)
                setIsLoading(false);
                // setbankaccount({"holder_name" : res?.holder_name , "account_number" : res?.account_number , "confirm_account_number" : res?.account_number , "ifsc":res?.ifsc , "account_type" : res?.account_type})
                Formik.setFieldValue("holder_name", res?.holder_name)
                Formik.setFieldValue("account_number", res?.account_number)
                Formik.setFieldValue("confirm_account_number", res?.account_number)
                Formik.setFieldValue("ifsc", res?.ifsc)
                Formik.setFieldValue("account_type", res?.account_type)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(true)
            })
    }

    useEffect(() => {
        getAccountDetail()
    }, [])


    return (
        <>
            <HeadingBanner title={"My Bank Accounts"} />
            <section>
                <div className="container">
                    <div className="mybankac-layout">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5"><Dashboard setting_data={settings} /></div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="mybankac-content">
                                    <div className="mybank-head pb-3">
                                        <h4>My Bank Accounts</h4>
                                    </div>

                                    {
                                        Isloading ? (

                                            <>
                                                <div className="aclist border px-3  pt-4">
                                                    <div className="row">
                                                        <div className=" col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="ackey">
                                                                <h2><Shimmer className="rounded" width={240} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={240} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={240} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={240} height={40} /></h2>

                                                            </div>
                                                            <div> <h2><Shimmer className="rounded" width={150} height={50} /></h2></div>
                                                        </div>
                                                        <div className=" col-xl-8 col-lg-6 col-md-6 col-sm-6">
                                                            <div className="acvalue">
                                                                <h2><Shimmer className="rounded" width={350} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={350} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={350} height={40} /></h2>
                                                                <h2><Shimmer className="rounded" width={350} height={40} /></h2>
                                                            </div>
                                                           
                                                        </div>
                                                      
                                                    </div>

                                                </div>
                                            </>) :

                                            (
                                                <>
                                                    {

                                                        !acc_list?.account_number ? (<div className="addbank-ac text-center border py-3">
                                                            <div className="bankimage my-3 ">
                                                                <img src="\assets\iocns\bank.png" className="img-fluid" />
                                                            </div>
                                                            <div className="addbank-info ">
                                                                <p>Add withdrawl account to get deposits of money at the time of selling</p>
                                                            </div>
                                                            <div className="addbank-btn text-white my-3">
                                                                <button className="text-white" data-bs-toggle="modal" data-bs-target="#addbankmodal" data-bs-whatever="@getbootstrap" >Add Bank Account</button>
                                                            </div>
                                                        </div>) :

                                                            (<div className="aclist border px-3  pt-4">
                                                                <div className="row">
                                                                    <div className=" col-xl-4 col-lg-6 col-md-6 col-sm-6">
                                                                        <div className="ackey">
                                                                            <h2>Account Holder Name</h2>
                                                                            <h2>Account Number</h2>
                                                                            <h2>IFSC Code</h2>
                                                                            <h2>Account Type</h2>
                                                                        </div>
                                                                    </div>
                                                                    <div className=" col-xl-8 col-lg-6 col-md-6 col-sm-6">
                                                                        <div className="acvalue">
                                                                            <h2>:   {acc_list?.holder_name}</h2>
                                                                            <h2>:   {acc_list?.account_number}</h2>
                                                                            <h2>:   {acc_list?.ifsc}</h2>
                                                                            <h2>:   {acc_list?.account_type}</h2>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <button className="btn  text-white" data-bs-toggle="modal" data-bs-target="#addbankmodal" onClick={() => { getAccountDetail() }}>Update</button>
                                                            </div>)
                                                    }
                                                </>)
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div className="modal fade Addbankacc-modal" id="addbankmodal" tabIndex={-1} aria-labelledby="addbankmodal" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content py-3">
                        <div className="modal-header">
                            <h3 className="modal-title" id="exampleModalLabel"> {acc_list?.account_number !== null ? 'Update' : 'Create'} Bank Accounts</h3>
                            <button className="d-none" id="clode_accountmodal" data-bs-toggle="modal" data-bs-target="#addbankmodal"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={Formik.handleSubmit}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12  form-group">
                                            <label className="col-form-label">Account Holder Name</label>
                                            <input type="text" className="form-control" id="recipient-name" placeholder="Account Holder Name" {...Formik.getFieldProps("holder_name")} />
                                            {
                                                Formik.touched.holder_name && Formik.errors.holder_name ? (<p className="text-danger m-0">{Formik.errors.holder_name}</p>) : null
                                            }
                                        </div>
                                        <div className="col-lg-6  col-md-6  col-sm-6 form-group">
                                            <label className="col-form-label">Account Number</label>
                                            <input type="text" className="form-control" id="recipient-name2" placeholder="Account Number"    {...Formik.getFieldProps("account_number")} />
                                            {
                                                Formik.touched.account_number && Formik.errors.account_number ? (<p className="text-danger m-0">{Formik.errors.account_number}</p>) : null
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6  col-sm-6  form-group">
                                            <label className="col-form-label">Confirm Account Number</label>
                                            <input type="text" className="form-control" id="recipient-name1" placeholder="Confirm Account Number"  {...Formik.getFieldProps("confirm_account_number")} />
                                            {
                                                Formik.touched.confirm_account_number && Formik.errors.confirm_account_number ? (<p className="text-danger m-0">{Formik.errors.confirm_account_number}</p>) : null
                                            }
                                        </div>

                                        <div className="col-lg-6 col-md-6  col-sm-6  form-group">
                                            <label className="col-form-label">IFSC Code</label>
                                            <input type="text" className="form-control" id="recipient-name3" placeholder="IFSC Code"  {...Formik.getFieldProps("ifsc")} />
                                            {
                                                Formik.touched.ifsc && Formik.errors.ifsc ? (<p className="text-danger m-0">{Formik.errors.ifsc}</p>) : null
                                            }
                                        </div>
                                        <div className="col-lg-6 col-md-6  col-sm-6 form-group" >
                                            <label className="col-form-label">Account Type</label>
                                            <select className="form-select" aria-label="addbankname" {...Formik.getFieldProps("account_type")}>
                                                <option value="" selected disabled>Select</option>
                                                <option value="Savings">Savings</option>
                                                <option value="Current">Current</option>
                                            </select>
                                            {
                                                Formik.touched.account_type && Formik.errors.account_type ? (<p className="text-danger m-0">{Formik.errors.account_type}</p>) : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="addbank-submit py-3 mx-3">
                                    <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Sumbit'}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default MyBankAccount;