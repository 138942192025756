"use client"

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import HeadingBanner from "../Components/HeadingBanner";
import axios from "axios";
import { Singlepage } from "../api/Pageapi";
import { Shimmer } from "react-shimmer";

export const Footerslug = () => {

    const [PageDetail, setPageDetail] = useState([])
    const [Isloading, setIsloading] = useState(true)

    const slug = useParams()


    const getslugDetail = () => {
        Singlepage(slug?.slug)
            .then((res) => {
                setPageDetail(res?.data)
                setIsloading(false)
            })
            .catch((err) => {
                console.log(err)
                setIsloading(true)
            })
    }

    useEffect(() => {
        getslugDetail()
    }, [slug])

    return (
        <>
            <HeadingBanner title={`${Isloading ? 'Loading...' : PageDetail?.name ? PageDetail?.name : ''}`} />

            <section className="my-5 py-5">
                <div className="container py-5">
                    {
                        Isloading ? Array.from({ length: 3 }).map((_, i) => (
                            <div className="my-3 " key={i}>
                                <div><Shimmer width={400} height={40} className="rounded" /></div>
                                <div><Shimmer height={150} className="rounded w-100 mt-2" /></div>
                            </div>
                        ))
                            : PageDetail ? (<h4 className='1bh-base fw-normal' dangerouslySetInnerHTML={{ __html: PageDetail?.description }}></h4>) : ''
                    }
                </div>
            </section>

        </>
    )
}