import React, { useContext, useEffect, useState } from "react";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup'
import { Logindetail } from "../context/Logindetail";
import moment from "moment/moment";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getprofile_fun, update_fun } from "../api/Dashboardapi";
import { City_fun, Country_fun, GenderApi, State_fun } from "../api/Controllapi";

const MyAccount = () => {

  const [file, setfile] = useState()
  const [gender, setgender] = useState([])
  const [imag, setimag] = useState()
  const [spinner, setspinner] = useState(false)
  const [CountryData, setCountryData] = useState([])
  const [StateData, setStateData] = useState([])
  const [countryid, setcountryid] = useState()
  const [CityData, setCityData] = useState()
  const [pandocs1, setpandocs1] = useState()
  const [pandocs2, setpandocs2] = useState()
  const [aadhardocs1, setaadhardocs1] = useState()
  const [aadhardocs2, setaadhardocs2] = useState()
  const router = useNavigate()

  const upload_images = () => {
    document.getElementById('my_file')?.click()
  }

  const { detail_info, setdetail_info } = useContext(Logindetail);

  const [initValue, setinitValue] = useState({

    // profile

    "name": `${detail_info?.name ? detail_info?.name : ""}`,
    "father_name": `${detail_info?.father_name ? detail_info?.father_name : ""}`,
    "phone_number": `${detail_info?.phone_number ? detail_info?.phone_number : ""}`,
    "email": `${detail_info?.email ? detail_info?.email : ""}`,
    "date_of_birth": `${detail_info?.date_of_birth ? detail_info?.date_of_birth : ""}`,
    "gender": `${detail_info?.gender ? detail_info?.gender : "female"}`,
    "avatar": `${detail_info?.avatar ? detail_info?.avatar : ""}`,

    // addresss

    "address_1": `${detail_info?.address_1 ? detail_info?.address_1 : ""}`,
    "address_2": `${detail_info?.address_2 ? detail_info?.address_2 : ""}`,
    "country": `${detail_info?.country ? detail_info?.country : ""}`,
    "state": `${detail_info?.state ? detail_info?.state : ""}`,
    "city": `${detail_info?.city ? detail_info?.city : ""}`,
    "postal": `${detail_info?.postal ? detail_info?.postal : ""}`,

    // Kyc

    "name_as_aadhar": `${detail_info?.name_as_aadhar ? detail_info?.name_as_aadhar : ''}`,
    "aadhar_number": `${detail_info?.aadhar_number ? detail_info?.aadhar_number : ''}`,
    "name_as_pan": `${detail_info?.name_as_pan ? detail_info?.name_as_pan : ''}`,
    "pan_number": `${detail_info?.pan_number ? detail_info?.pan_number : ''}`,
    "pan_documents": `${detail_info?.pan_documents ? detail_info?.pan_documents : ''}`,
    "aadhar_documents": `${detail_info?.aadhar_documents ? detail_info?.aadhar_documents : ''}`
  })




  const updateschema = Yup.object().shape({
    "name": Yup.string()
      .required("Enter name")
      .matches(/^[a-zA-Z ]*$/ , "Invalid name" ),

    "father_name": Yup.string()
      .required("Enter mother name")
      .matches(/^[a-zA-Z ]*$/ , "Invalid name" ),

    "phone_number": Yup.string()
      .min(10, 'Min 10 digits')
      .max(10, 'Max 10 digits')
      .required("Enter your Phone Number")
      .matches(/^[0-9]+$/, "Invalid Phone Number"),

    "email": Yup.string().email('Invalid email')
      .required("Enter Your Email"),

    'date_of_birth': Yup.date()
      .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
      .required("Enter DOB"),

    "gender": Yup.string()
      .required("Enetr gender"),

    "avatar": Yup.mixed(),

    "address_1": Yup.string()
      .required("Enter Your Address"),

    "address_2": Yup.string(),

    "country": Yup.string(),

    "state": Yup.string(),

    "city": Yup.string(),

    "postal": Yup.string(),

    "name_as_aadhar": Yup.string().required("Enter Aadhar name"),

    "aadhar_number": Yup.string().required("Enter Aadhar number")
    .min(12, 'Min 12 digits')
    .max(12, 'Max 12 digits')
    .matches(/^[0-9]+$/, "Invalid  Aadhar number"),

    "name_as_pan": Yup.string().required("Enter Pan name"),

    "pan_number": Yup.string().required("Enter Pan number")
    .min(10, 'Min 10 digits')
    .max(10, 'Max 10 digits')
    .matches(/^[0-9]+$/, "Invalid Pan number"),

    "pan_documents": Yup.mixed(),

    "aadhar_documents": Yup.mixed(),


  })


  function handlechange(e) {
    setimag(e.target.files[0])
    setfile(URL.createObjectURL(e.target.files[0]))
  }

  const handleaadhar = (e) => {
    setaadhardocs1(e.target.files[0])
    setaadhardocs2(e.target.files[1])
  }

  const handlepan = (e) => {
    setpandocs1(e.target.files[0])
    setpandocs2(e.target.files[1])
  }

  const Formrik = useFormik({
    initialValues: initValue,
    validationSchema: updateschema,
    onSubmit: async (value, { setErrors, setStatus, resetForm }) => {

      setspinner(true)
      const DOB = moment(value.date_of_birth).format('YYYY-MM-DD')

      const formdata = new FormData()
      formdata.append("name", value.name)
      formdata.append("father_name", value.father_name)
      formdata.append("phone_number", value.phone_number)
      formdata.append("email", value.email)
      formdata.append("date_of_birth", DOB)
      formdata.append("gender", value.gender)
      formdata.append("address_1", value.address_1)
      formdata.append("address_2", value.address_2)
      formdata.append("country", value.country)
      formdata.append("state", value.state)
      formdata.append("city", value.city)
      formdata.append("postal", value.postal)
      formdata.append("avatar", imag)
      formdata.append("name_as_aadhar", value.name_as_aadhar)
      formdata.append("aadhar_number", value.aadhar_number)
      formdata.append("name_as_pan", value.name_as_pan)
      formdata.append("pan_number", value.pan_number)
      formdata.append("pan_documents[0]", pandocs1)
      formdata.append("pan_documents[1]", pandocs2)
      formdata.append("aadhar_documents[0]", aadhardocs1)
      formdata.append("aadhar_documents[1]", aadhardocs2)

      update_fun(formdata)
        .then((res) => {
          setspinner(false)
          toast.success(res?.message)
          handlegetprofile()
        })
        .catch((err) => {
          console.log(err)
          setspinner(true)
        })
    }
  });

  const genderapi = async () => {
    try {
      GenderApi().then(res => {
        setgender(res?.data)
      }).catch(err => {
        console.log(err)
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const getcountry = async () => {
    Country_fun()
      .then((res) => {
        setCountryData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    const value = Formrik.getFieldProps('country').value;
    const value2 = Formrik.getFieldProps('state').value;
    if (value) {
      getstate(value)
    }

    if (value2) {
      getcity(value, value2)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Formrik.getFieldProps('country').value]);

  const getstate = (id) => {
    Formrik.setFieldValue("country", id);
    setcountryid(id)
    State_fun(id)
      .then((res) => {
        setStateData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const getcity = (con, id) => {

    City_fun(con, id)
      .then((res) => {
        setCityData(res?.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handlegetprofile = () => {
    getprofile_fun()
      .then((res) => {
        localStorage.setItem("green_login", JSON.stringify(res?.data));
        setdetail_info(res?.data)
        setfile(res?.data?.avatar);
      })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    genderapi()
    getcountry()
    setfile(detail_info?.avatar ?? '')
    handlegetprofile()
  }, [])


  return (
    <>
      <section className='breadcamp'>
        <div className='container'>
          <div className='d-flex gap-2'>
            <h4 className='mb-0' style={{ cursor: "pointer" }}><span onClick={() => { router('/') }} >Home</span>{`>> My Account`}</h4>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="myaccont_layout">

            <div className="myaccount-title text-center">
              <h2>My Account</h2>
            </div>

            <div className="myaccount-info mt-5">
              <form onSubmit={Formrik.handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="myac-basicdetail">
                      <div className="bd-head pb-3" >
                        <h4>Basic Details</h4>
                      </div>
                      <div className="bd-form border">

                        <div className="profice-pic text-center">
                          <img src={file ? file : '/assets/images/user.jfif'} id="uploadimg" className="img-fluid" onClick={upload_images} />
                          <input type="file" id="my_file" className="d-none" accept=".jpeg " onChange={handlechange} />
                          {
                            Formrik.touched.avatar && Formrik.errors.avatar ? (<p className="text-danger m-0">{Formrik.errors.avatar}</p>) : null
                          }
                        </div>
                        <div className="row p-4">
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label> Name</label>
                            <input type="text" className="form-control" id="inputname" placeholder=" Name"  {...Formrik.getFieldProps("name")} />
                            {
                              Formrik.touched.name && Formrik.errors.name ? (<span className="text-danger">{Formrik.errors.name}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6">
                            <label >Mother Name</label>
                            <input type="text" className="form-control" id="inputfathername" placeholder="Mother Name" {...Formrik.getFieldProps("father_name")} />
                            {
                              Formrik.touched.father_name && Formrik.errors.father_name ? (<span className="text-danger">{Formrik.errors.father_name}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6">
                            <label >Email</label>
                            <input type="Email" className="form-control" id="inputemail" placeholder="Email"  {...Formrik.getFieldProps("email")} />
                            {
                              Formrik.touched.email && Formrik.errors.email ? (<span className="text-danger">{Formrik.errors.email}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6">
                            <label >Phone</label>
                            <input type="text" className="form-control" id="inputPhone" placeholder="Phone" {...Formrik.getFieldProps("phone_number")} />
                            {
                              Formrik.touched.phone_number && Formrik.errors.phone_number ? (<span className="text-danger">{Formrik.errors.phone_number}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6">
                            <label >Date of Birth</label>
                            <input type="date" className="form-control" id="inputemail2" placeholder="D-O-B" {...Formrik.getFieldProps("date_of_birth")} pattern="\d{2}-\d{2}-\d{4}" />
                            {
                              Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                            }
                          </div>
                          <div className="form-group col-md-6  col-sm-6 col-6">
                            <label>Gender</label>
                            <select className="form-select" id="inputGroupSelect01" {...Formrik.getFieldProps("gender")} >
                              {/* {
                                gender ? gender.map((r, i) => {
                                  return (
                                    <option value={r.id} key={i}>{r.name}</option>
                                  )
                                }) : ""
                              } */}
                              <option value="" selected disabled>Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>

                            </select>
                            {
                              Formrik.touched.gender && Formrik.errors.gender ? (<span className="text-danger">{Formrik.errors.gender}</span>) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myac-addrdetail mt-5">
                      <div className="bd-head pb-3" >
                        <h4>Address Details</h4>
                      </div>
                      <div className="bd-form border">
                        <div className="row">
                          <div className="form-group col-md-12">
                            <label>Address Line 1</label>
                            <input type="text" className="form-control" id="inputaddress" placeholder="Address Line 1"  {...Formrik.getFieldProps("address_1")} />
                            {
                              Formrik.touched.address_1 && Formrik.errors.address_1 ? (<p className="text-danger m-0">{Formrik.errors.address_1}</p>) : null
                            }
                          </div>
                          <div className="form-group col-md-12">
                            <label>Address Line 2</label>
                            <input type="text" className="form-control" id="inputaddress2" placeholder="Address Line 2"  {...Formrik.getFieldProps("address_2")} />
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Country</label>
                            <select className="form-select" id="inputGroupSelect00"   {...Formrik.getFieldProps("country")}  >
                              <option value={''} selected disabled>Select</option>
                              {
                                CountryData?.map((r) => {
                                  return (
                                    <option value={r?.id} > {r?.name}  </option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6 ">
                            <label>State</label>
                            <select className="form-select" id="inputGroupSelect02"   {...Formrik.getFieldProps("state")}   >
                              <option value={''} selected disabled>Select</option>
                              {
                                StateData?.map((r) => {
                                  return (
                                    <option value={r?.id}>{r?.state}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6"  >
                            <label>City</label>
                            <select className="form-select" id="inputGroupSelect03" {...Formrik.getFieldProps("city")} >
                              <option value={''} selected disabled>Select</option>
                              {
                                CityData?.map((r) => {
                                  return (
                                    <option value={r?.id}>{r?.city}</option>
                                  )
                                })
                              }
                            </select>
                          </div>

                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Postal code</label>
                            <input type="text" className="form-control" id="inputaddress2" placeholder="Postal code"  {...Formrik.getFieldProps("postal")} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="myac-kyc pt-lg-0 pt-xl-0 pt-md-5 pt-4">
                      <div className="bd-head pb-3 " >
                        <h4>KYC Info</h4>
                      </div>
                      <div className="bd-form border">
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Name as Aadhar Card</label>
                            <input type="text" className="form-control" id="inputaddress3" placeholder="Name as Aadhar Card" {...Formrik.getFieldProps("name_as_aadhar")} />
                            {
                              Formrik.touched.name_as_aadhar && Formrik.errors.name_as_aadhar ? (<p className="text-danger">{Formrik.errors.name_as_aadhar}</p>) : null
                            }
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Aadhar Number</label>
                            <input type="text" className="form-control" id="inputaddress4" placeholder="Aadhar Number" {...Formrik.getFieldProps("aadhar_number")} />
                            {
                              Formrik.touched.aadhar_number && Formrik.errors.aadhar_number ? (<p className="text-danger m-0">{Formrik.errors.aadhar_number}</p>) : null
                            }
                          </div>
                          <div className="form-group mb-3 col-md-12 col-12">
                            <label>Upload Aadhar Documents *(PNG,JPG,JPEG)</label>
                            <input className="form-control" id="inputGroupFile000" type="file" accept="image/jpg, image/jpeg, image/png" multiple="multiple" onChange={handleaadhar} />
                            {
                              Formrik.touched.aadhar_documents && Formrik.errors.aadhar_documents ? (<p className="text-danger m-0">{Formrik.errors.aadhar_documents}</p>) : null
                            }
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Name As Pan Card</label>
                            <input type="text" className="form-control" id="inputaddress5" placeholder="Name As Pan Card" {...Formrik.getFieldProps("name_as_pan")} />
                            {
                              Formrik.touched.name_as_pan && Formrik.errors.name_as_pan ? (<p className="text-danger m-0">{Formrik.errors.name_as_pan}</p>) : null
                            }
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Pan Number</label>
                            <input type="text" className="form-control" id="inputaddress6" placeholder="Pan Number"   {...Formrik.getFieldProps("pan_number")} />
                            {
                              Formrik.touched.pan_number && Formrik.errors.pan_number ? (<p className="text-danger m-0">{Formrik.errors.pan_number}</p>) : null
                            }
                          </div>

                          <div className="form-group mb-3 col-md-12 col-12">
                            <label>Upload Pan Documents *(PNG,JPG,JPEG)</label>
                            <input className="form-control" id="inputGroupFile0001" accept="image/jpg, image/jpeg, image/png" multiple="multiple" type="file" onChange={handlepan} />
                            {
                              Formrik.touched.pan_documents && Formrik.errors.pan_documents ? (<p className="text-danger m-0">{Formrik.errors.pan_documents}</p>) : null
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="myac-withdraw mt-5">
                      <div className="bd-head pb-3" >
                        <h4>Withdrawal Method</h4>
                      </div>
                      <div className="bd-form border">
                        <div className="row">
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Name as Aadhar Card</label>
                            <input type="text" className="form-control" id="inputaddress7" placeholder="Name as Aadhar Card" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Aadhar Number</label>
                            <input type="text" className="form-control" id="inputaddress8" placeholder="Aadhar Number" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Name As Pan Card</label>
                            <input type="text" className="form-control" id="inputaddress9" placeholder="Name As Pan Card" />
                          </div>
                          <div className="form-group col-md-6 col-sm-6 col-6">
                            <label>Pan Number</label>
                            <input type="text" className="form-control" id="inputaddress10" placeholder="Pan Number" />
                          </div>
                          <div className="form-group col-md-12 col-12  mb-3">
                            <label>Upload Documents *(PNG,JPG,JPEG)</label>
                            <input className="form-control" id="inputGroupFile03" type="file" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="myac-submit submit mt-sm-5 mt-3">
                  <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm " role="status"></span> : 'Submit'}</button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </section>

    </>
  )
}
export default MyAccount