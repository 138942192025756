import React, { useContext, useEffect, useState } from "react";
import HeadingBanner from "../Components/HeadingBanner.jsx";
import Dashboard from "../Components/Dashboard.jsx";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { Logindetail } from "../context/Logindetail.js";
import moment from "moment";
import toast from "react-hot-toast";
import { getprofile_fun, update_fun } from "../api/Dashboardapi.js";
import { GenderApi } from "../api/Controllapi.js";
const Profile = ({settings}) => {

    const [file, setfile] = useState();
    const [imag, setimag] = useState();
    const [gender, setgender] = useState([])
    const [update_data ,setupdate_data] =useState([])
    const [spinner , setspinner]=useState(false)
    const [ProfileData ,setProfileData] =useState([])


    const upload_images = () => {
        document.getElementById('my_file')?.click()
    }

    function handlechange(e) {
        // Formrik.setFieldValue('avatar', e.target.files[0]);
        setfile(URL.createObjectURL(e.target.files[0])); 
        setimag(e.target.files[0])
    }

    const { detail_info  , setdetail_info} = useContext(Logindetail)



//   console.log('detail_info?.avatardetail_info?.avatar',detail_info?.avatar);
  
    const initvalue = {
        "name": `${detail_info?.name ? detail_info?.name :  ''}`,
        "father_name": `${detail_info?.father_name ? detail_info?.father_name : ''}`,
        "phone_number": `${detail_info?.phone_number ? detail_info?.phone_number :  ''}`,
        "email": `${detail_info?.email ? detail_info?.email  : ''}`,
        "date_of_birth": `${detail_info?.date_of_birth ? detail_info?.date_of_birth :  ''}`,
        "gender": `${detail_info?.gender ? detail_info?.gender :'female'}`,
        "avatar": `${detail_info?.avatar ? detail_info?.avatar :  ''}`,
    }

    const updateschema = Yup.object().shape({
        "name": Yup.string()
        .required("Enter name")
        .matches(/^[a-zA-Z ]*$/ , "Invalid name" ),
  
      "father_name": Yup.string()
        .required("Enter mother name")
        .matches(/^[a-zA-Z ]*$/ , "Invalid name" ),
  
        "phone_number": Yup.string()
            .min(10, 'Min 10 digits')
            .max(10, 'Max 10 digits')
            .required("Enter your Phone Number")
            .matches(/^[0-9]+$/, "Invalid  Phone Number"),

        "email": Yup.string().email('Invalid Email')
            .required("Enter Your Email"),

        "date_of_birth": Yup.date()
        .max(new Date(Date.now() - 567648000000), "You must be at least 18 years")
        .required("Enter DOB"),

        "gender": Yup.string(),

        "avatar": Yup.mixed(),
    })

    const Formrik = useFormik({

        initialValues: initvalue,
        validationSchema: updateschema,
        onSubmit: async (value, { setErrors, setStatus, resetForm }) => {
            

            setspinner(true)
            const DOB  = moment(value.date_of_birth).format('YYYY-MM-DD')

            const formdata = new FormData()
            formdata.append("name", value.name)
            formdata.append("father_name", value.father_name)
            formdata.append("phone_number", value.phone_number)
            formdata.append("email", value.email)
            formdata.append("date_of_birth", DOB)
            formdata.append("gender", value.gender)
            formdata.append("avatar", imag)

            // const input = Object.fromEntries(formdata);
            // console.log("inputs",input)

            await update_fun(formdata)
                .then((res) => {
                    console.log(res)
                    toast.success(res.message);
                    setupdate_data(res?.user);
                    handlegetprofile();
                    setspinner(false) 
                })
                .catch((err) => {
                    console.log(err)
                    setspinner(false)

                })
        }
    })


    const genderapi = async () => {
        try {

            GenderApi(res => {
                setgender(res?.data)
            }).catch(err => {
                console.log(err)
            })
        }
        catch (err) {
            console.log(err)
        }

    }

    const handlegetprofile = ()=>{
        getprofile_fun()
        .then((res)=>{
            localStorage.setItem("green_login", JSON.stringify(res?.data));
            setdetail_info(res?.data)
            setfile(res?.data?.avatar);
        })
        .catch((err)=>{
            console.log(err)
        })
    }


    useEffect(() => {
        genderapi(); 
        setfile(detail_info?.avatar ?? '');
        setimag(detail_info?.avatar ?? '');
        handlegetprofile()
    }, [])


    return (
        <>
            <HeadingBanner title={"My Profile"} />
            <section>
                <div className="container">
                    <div className="profile_layout">
                        <div className="row">
                            <div className="col-xl-4 col-lg-5">
                            <Dashboard setting_data={settings} />
                            </div>
                            <div className="col-xl-8 col-lg-7">
                                <div className="myprofile-content">
                                    <div className="bd-heads pb-3" >
                                        <h4>My Profile</h4>
                                    </div>
                                    <div className="bd-form border">
                                        <form onSubmit={Formrik.handleSubmit}>
                                            <div className="profice-pic text-center">
                                                
                                                <img src={file ? file : "/assets/images/user.jfif"} id="uploadimg" className="img-fluid" onClick={upload_images} alt="profile_img" />
                                                <input type="file" id="my_file" className="d-none" onChange={handlechange} />
                                                {
                                                    Formrik.touched.avatar && Formrik.errors.avatar ? (<p className="text-danger">{Formrik.errors.avatar}</p>) : null
                                                }
                                            </div>
                                            <div className="row p-4">
                                                <div className="form-group col-md-6 col-sm-6 col-6">
                                                    <label> Name</label>
                                                    <input type="text" className="form-control" id="inputname" placeholder=" Name" {...Formrik.getFieldProps("name")} />
                                                    {
                                                      Formrik.touched.name && Formrik.errors.name ? (<span className="text-danger">{Formrik.errors.name}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6">
                                                    <label >Mother Name</label>
                                                    <input type="text" className="form-control" id="inputfathername" placeholder="Mother Name" {...Formrik.getFieldProps("father_name")} />
                                                    {
                                                        Formrik.touched.father_name && Formrik.errors.father_name ? (<span className="text-danger">{Formrik.errors.father_name}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6">
                                                    <label >Email</label>
                                                    <input type="text" className="form-control" id="inputemail1" placeholder="Email"  {...Formrik.getFieldProps("email")} />
                                                    {
                                                        Formrik.touched.email && Formrik.errors.email ? (<span className="text-danger">{Formrik.errors.email}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6">
                                                    <label >Phone</label>
                                                    <input type="text" className="form-control" id="inputPhone" placeholder="Phone" {...Formrik.getFieldProps("phone_number")} />
                                                    {
                                                        Formrik.touched.phone_number && Formrik.errors.phone_number ? (<span className="text-danger">{Formrik.errors.phone_number}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6">
                                                    <label >Date of Birth</label>
                                                    <input type="date" className="form-control" id="inputemail2" placeholder="D-O-B" {...Formrik.getFieldProps("date_of_birth")} />
                                                    {
                                                        Formrik.touched.date_of_birth && Formrik.errors.date_of_birth ? (<span className="text-danger">{Formrik.errors.date_of_birth}</span>) : null
                                                    }
                                                </div>
                                                <div className="form-group col-md-6  col-sm-6 col-6">
                                                    <label>Gender</label>
                                                    <select className="form-select" id="inputGroupSelect01" {...Formrik.getFieldProps("gender")} >
                                                        {/* {
                                                            gender ? gender.map((r,i) => {
                                                                return (
                                                                    <option value={r.id} key={i}>{r.name}</option>
                                                                )
                                                            }) : ""
                                                        } */}
                                                        <option value="" selected disabled>Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                       
                                                    </select>
                                                    {
                                                        Formrik.touched.gender && Formrik.errors.gender ? (<span className="text-danger">{Formrik.errors.gender}</span>) : null
                                                    }
                                                </div>
                                            </div>
                                            <div className="update-profile  mx-3">
                                            <button className='text-white' type="submit">{spinner ? <span className="spinner-border spinner-border-sm" role="status"></span> : 'Update'}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}
export default Profile